import { captureException } from '@sentry/react';

import Feature from 'ol/Feature';
import { DragBox, Select, Translate } from 'ol/interaction';
import { fromExtent } from 'ol/geom/Polygon';
import { platformModifierKeyOnly, pointerMove } from 'ol/events/condition';

import {
    BASE_LAYER_ID,
    MAP_LAYERS,
    TOOLS_ID,
    selectNonHighLightLayers,
    tagsNonHighLightLayers,
    GEOMETRY_TYPE_ENUM
} from '../../../Constants/Constant';
import { Observer } from '../../../Utils/Observer';
import { layerTracker } from '../MapInit';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { HIGHLIGHT_STYLE_NUMERICAL, highlightStyle } from '../MapBase';
import { changeMapCursor, isNumericalLayer } from '../../../Utils/HelperFunctions';

class SelectTool extends Observer {
    bpLotExtent: $TSFixMe;

    dragBox: $TSFixMe;

    hover: $TSFixMe;

    invalidSpace: $TSFixMe;

    isPureSelectTool: $TSFixMe;

    isTagTool: boolean;

    lastFeatures: $TSFixMe;

    lotFeature: $TSFixMe;

    mapObj: $TSFixMe;

    nonHighLightLayers: $TSFixMe;

    select: $TSFixMe;

    translate: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.select = null;
        this.hover = null;
        this.dragBox = null;
        this.translate = null;
        this.bpLotExtent = null;
        this.lotFeature = null;
        this.invalidSpace = false;
        this.lastFeatures = [];
        this.isPureSelectTool = false;
        this.isTagTool = false;
        this.nonHighLightLayers = () => {};
    }

    on({ toolId }: $TSFixMe) {
        this.off();
        this.isPureSelectTool = toolId === TOOLS_ID.SELECT_TOOL;
        this.isTagTool = toolId === TOOLS_ID.TAG_TOOL;
        this.nonHighLightLayers = this.isPureSelectTool ? selectNonHighLightLayers : tagsNonHighLightLayers;

        this.mapObj.map.on('pointermove', this.changeCursor);

        if (this.mapObj.isBlueprintMap) {
            const bpSheetExtent = this.mapObj.baseLayer.get('bp_page_extent');
            this.bpLotExtent = new Feature(fromExtent(bpSheetExtent));
        }

        // for single and multi select(using shift key)
        this.select = new Select({
            // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: (featu... Remove this comment to see the full error message
            wrapX: false,
            filter: (feature, layer) => {
                const layerData = layer.getProperties()?.layerData;
                const geometryType = layerData?.feature?.geometry_type;
                // cannot use select when using tags
                const isTypicaFeature = geometryType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT && !this.isPureSelectTool;
                return !this.nonHighLightLayers(layer?.get('name')) && isTypicaFeature;
            }
        });

        this.mapObj.map.addInteraction(this.select);

        this.hover = new Select({
            condition: pointerMove,
            toggleCondition: () => false,
            filter: (feature, layer) =>
                !this.nonHighLightLayers(layer?.get('name')) && !this.select.getFeatures().getArray().includes(feature),

            style: feature => {
                const layer = this.mapObj.getLayerById(feature.get('layerId'));
                return layer && isNumericalLayer(layer)
                    ? HIGHLIGHT_STYLE_NUMERICAL
                    : // @ts-expect-error TS(2322): Type 'FeatureLike' is not assignable to type 'null... Remove this comment to see the full error message
                      highlightStyle({ layer, feature });
            }
        });
        this.mapObj.map.addInteraction(this.hover);

        this.select.on('select', (e: $TSFixMe) => {
            const selectedFeatures = e.target.getFeatures().getArray();

            this.notifyObservers(
                this.isPureSelectTool ? TOOL_EVENT.SELECT_FEATURES : TOOL_EVENT.TAGS_SELECT_FEATURES,
                selectedFeatures
            );
        });

        // a DragBox interaction used to select features by drawing boxes using ctrl Key
        this.dragBox = new DragBox({
            condition: platformModifierKeyOnly
        });

        this.mapObj.map.addInteraction(this.dragBox);

        // clear selection when drawing a new box and when clicking on the map
        this.dragBox.on('boxstart', () => {
            this.select.getFeatures().clear();
        });
        this.dragBox.on('boxend', this.handleDragboxEnd);

        if (this.isTagTool) {
            document.addEventListener('keydown', this.onKeyPress);
        }

        if (this.isPureSelectTool) {
            this.translate = new Translate({
                features: this.select.getFeatures()
            });
            this.mapObj.map.addInteraction(this.translate);

            this.translate.on('translatestart', (e: $TSFixMe) => {
                this.lastFeatures = e.features.getArray().map((f: $TSFixMe) => f.clone());
            });

            this.translate.on('translateend', this.handleTranslateEnd);
        }
    }

    onKeyPress = (event: $TSFixMe) => {
        if ((event.ctrlKey || event.metaKey) && event.keyCode === 65) {
            event.preventDefault();
            this.selectAll();
        }
    };

    selectAll = () => {
        const selected: $TSFixMe = [];

        const allMapLayers = this.mapObj.getLayers();

        allMapLayers.forEach((layer: $TSFixMe) => {
            const isVisible = layer.getVisible();
            if (isVisible && layer.get('name') === MAP_LAYERS.OUTPUT) {
                const layerSource = layer.getSource();
                // Loop only on features which are available in the viewport
                layerSource.forEachFeature((outputLayerFeature: $TSFixMe) => {
                    selected.push(outputLayerFeature);
                });
            }
        });

        this.select.getFeatures().clear();
        this.select.getFeatures().extend(selected);
        this.notifyObservers(TOOL_EVENT.TAGS_SELECT_FEATURES, selected);
    };

    handleDragboxEnd = () => {
        const boxExtent = this.dragBox.getGeometry().getExtent();

        const selected: $TSFixMe = [];

        this.mapObj.getLayers().forEach((layer: $TSFixMe) => {
            if (!this.nonHighLightLayers(layer?.get('name')) && layer?.get('id') !== BASE_LAYER_ID) {
                try {
                    layer.getSource().forEachFeatureIntersectingExtent(boxExtent, (feature: $TSFixMe) => {
                        selected.push(feature);
                    });
                } catch (err) {
                    captureException(new Error("This layer features can't be selected"));
                }
            }
        });

        this.select.getFeatures().extend(selected);
        this.notifyObservers(
            this.isPureSelectTool ? TOOL_EVENT.SELECT_FEATURES : TOOL_EVENT.TAGS_SELECT_FEATURES,
            selected
        );
    };

    changeCursor = (e: $TSFixMe) => {
        this.invalidSpace = !this.mapObj.coordsExistsInParcel(
            e.coordinate,
            this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
        );
        changeMapCursor(this.invalidSpace, 'not-allowed');
        this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
            if (!this.nonHighLightLayers(_layer?.get('name'))) {
                // @ts-expect-error TS(2531): Object is possibly 'null'.
                document.getElementById('map').style.cursor = 'pointer';
                return true;
            }
            return false;
        });
    };

    handleTranslateEnd = (e: $TSFixMe) => {
        e.features.forEach((feature: $TSFixMe) => {
            const geom = feature.getGeometry();

            // handling out_of_extent case
            const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
                geom,
                boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
            });

            if (is_out_of_extent) {
                this.lastFeatures.forEach((lastFeature: $TSFixMe) => {
                    if (
                        lastFeature.get('layerId') === feature.get('layerId') &&
                        lastFeature.get('id') === feature.get('id')
                    ) {
                        feature.setGeometry(lastFeature.getGeometry());
                    }
                });
            } else {
                const layerId = feature.get('layerId');
                // Push layer in tracker
                layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
            }
        });
        if (layerTracker.getArray().length) {
            this.notifyObservers(TOOL_EVENT.MOVE_FEATURE);
        }
    };

    off() {
        this.mapObj.map.un('pointermove', this.changeCursor);
        if (this.select) {
            this.select.getFeatures().clear();
            this.mapObj.map.removeInteraction(this.select);
        }
        if (this.hover) {
            this.mapObj.map.removeInteraction(this.hover);
        }
        if (this.dragBox) {
            this.mapObj.map.removeInteraction(this.dragBox);
            this.dragBox.un('boxend', this.handleDragboxEnd);
        }
        if (this.translate) {
            this.mapObj.map.removeInteraction(this.translate);
            this.translate.un('translateend', this.handleTranslateEnd);
        }

        document.removeEventListener('keydown', this.onKeyPress);
        this.lotFeature = null;
        this.bpLotExtent = null;
        this.lastFeatures = [];
        this.notifyObservers(this.isPureSelectTool ? TOOL_EVENT.SELECT_FEATURES : TOOL_EVENT.CLEAR_TAGS_SELECTION, []);
    }
}

export default SelectTool;
