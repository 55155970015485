import Select from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import { fromExtent } from 'ol/geom/Polygon';
import { Translate } from 'ol/interaction';
import booleanContains from '@turf/boolean-contains';
import booleanIntersects from '@turf/boolean-intersects';

import {
    GEOMETRY_TYPE_STRING,
    LAYER_INDEX,
    MAP_LAYERS,
    TOOLS_ID,
    GEOMETRY_TYPE_ENUM
} from '../../../Constants/Constant';

import { layerTracker, outputMap } from '../MapInit';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { Observer } from '../../../Utils/Observer';
import { darkStroke, lightStroke } from '../../../Utils/olutils';

class LassoSelect extends Observer {
    bpLotExtent: $TSFixMe;

    draw: $TSFixMe;

    invalidSpace: $TSFixMe;

    isPureLassoSelectTool: $TSFixMe;

    isLassoTagTool: boolean;

    lastFeatures: $TSFixMe;

    layer: $TSFixMe;

    lotFeature: $TSFixMe;

    mapObj: $TSFixMe;

    select: $TSFixMe;

    src: $TSFixMe;

    translate: $TSFixMe;

    nonHighLightLayers: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        super();
        this.mapObj = mapObj;
        this.select = null;
        this.layer = null;
        this.src = null;
        this.translate = null;
        this.bpLotExtent = null;
        this.lotFeature = null;
        this.invalidSpace = false;
        this.lastFeatures = [];
        this.isPureLassoSelectTool = false;
        this.isLassoTagTool = false;
    }

    on({ toolId }: $TSFixMe) {
        this.off();
        this.isLassoTagTool = toolId === TOOLS_ID.LASSO_TAG;

        if (this.mapObj.isBlueprintMap) {
            const bpSheetExtent = this.mapObj.baseLayer.get('bp_page_extent');
            this.bpLotExtent = new Feature(fromExtent(bpSheetExtent));
        }

        this.src = new VectorSource({ wrapX: false });
        this.layer = new VectorLayer({
            // @ts-expect-error TS(2345): Argument of type '{ id: string; source: any; style... Remove this comment to see the full error message
            id: 'empty-draw-layer',
            source: this.src,
            style: [lightStroke, darkStroke],
            zIndex: LAYER_INDEX.DRAW
        });
        this.mapObj.addLayer(this.layer);

        this.draw = new Draw({
            type: GEOMETRY_TYPE_STRING.POLYGON,
            source: this.src,
            style: [lightStroke, darkStroke],
            condition: e => {
                const mouseClick = e.originalEvent.button;
                if (mouseClick === 2 || mouseClick === 1) {
                    return false;
                }
                return true;
            },
            snapTolerance: 1,
            ...(this.mapObj.enableRightClickDrag && { dragVertexDelay: 0 })
        });
        this.mapObj.map.addInteraction(this.draw);
        this.draw.on('drawstart', this.handleDrawStart);
        this.draw.on('drawend', this.handleDrawEnd);

        this.select = new Select({
            // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: () => ... Remove this comment to see the full error message
            wrapX: false,
            filter: () => false,
            condition: () => false
        });

        this.mapObj.map.addInteraction(this.select);
        document.addEventListener('keydown', this.onKeyPress);

        // Adding translate(move) interaction only if user selects free-hand select tool
        if (this.isPureLassoSelectTool) {
            this.translate = new Translate({
                features: this.select.getFeatures()
            });

            this.mapObj.map.addInteraction(this.translate);

            this.translate.on('translatestart', (e: $TSFixMe) => {
                this.lastFeatures = e.features.getArray().map((f: $TSFixMe) => f.clone());
            });

            this.translate.on('translateend', this.handleTranslateEnd);
        }
    }

    onKeyPress = (event: $TSFixMe) => {
        if (this.isLassoTagTool && (event.ctrlKey || event.metaKey) && event.keyCode === 65) {
            event.preventDefault();
            this.selectAll();
        } else {
            this.removeLastPointOnBack(event);
        }
    };

    selectAll = () => {
        const selected: $TSFixMe = [];

        const allMapLayers = this.mapObj.getLayers();

        allMapLayers.forEach((layer: $TSFixMe) => {
            const isVisible = layer.getVisible();
            if (isVisible && layer.get('name') === MAP_LAYERS.OUTPUT) {
                const layerSource = layer.getSource();
                // Loop only on features which are available in the viewport
                layerSource.forEachFeature((outputLayerFeature: $TSFixMe) => {
                    selected.push(outputLayerFeature);
                });
            }
        });

        this.select.getFeatures().clear();
        this.select.getFeatures().extend(selected);
        this.notifyObservers(TOOL_EVENT.TAGS_SELECT_FEATURES, selected);
    };

    /**
     * Remove last added point on pressing backspace
     * @param {Event} event
     */
    removeLastPointOnBack = (event: $TSFixMe) => {
        if (event.stopPropagation) event.stopPropagation();

        const KeyID = event.keyCode;
        if (KeyID === 8) {
            this.draw.removeLastPoint();
        }
        if (KeyID === 27) {
            this.draw.abortDrawing();
        }
    };

    /**
     * Clear the drawing on map and remove all selected features
     */
    clearDrawing() {
        this.layer?.getSource()?.clear();
        this.select?.getFeatures()?.clear();

        this.notifyObservers(
            this.isPureLassoSelectTool ? TOOL_EVENT.SELECT_FEATURES : TOOL_EVENT.TAGS_SELECT_FEATURES,
            []
        );
    }

    handleDrawStart = () => {
        this.clearDrawing();
    };

    /**
     * Loop through the features which are in viewport
     * Find contained or intersected features from drawn line
     * @param {Event} e
     */
    handleDrawEnd = (e: $TSFixMe) => {
        const { feature } = e;

        const drawnFeatureGeojson = this.getGeojsonByFeature(feature);

        const allMapLayers = this.mapObj.getLayers();

        allMapLayers.forEach((layer: $TSFixMe) => {
            const isVisible = layer.getVisible();
            if (isVisible && layer.get('name') === MAP_LAYERS.OUTPUT) {
                const layerData = layer.getProperties()?.layerData;
                const geometryType = layerData?.feature?.geometry_type;
                const isTypicalFeat = geometryType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT;
                if (!isTypicalFeat || this.isPureLassoSelectTool) {
                    const layerSource = layer.getSource();
                    // Loop only on features which are available in the viewport
                    layerSource.forEachFeature((outputLayerFeature: $TSFixMe) => {
                        const outputLayerFeatureGeojson = this.getGeojsonByFeature(outputLayerFeature);
                        // fix error when polygon feature have empty/no coordinates
                        if (!outputLayerFeatureGeojson?.geometry?.coordinates?.length) {
                            return;
                        }
                        const isContain = booleanContains(drawnFeatureGeojson, outputLayerFeatureGeojson);
                        const isIntersect = booleanIntersects(drawnFeatureGeojson, outputLayerFeatureGeojson);
                        if (isContain || isIntersect) {
                            this.select.getFeatures().push(outputLayerFeature);
                        }
                    });
                }
            }
        });
        // this is to remove drawing
        this.layer.setSource(new VectorSource({ wrapX: false }));

        const selectedFeatures = this.select.getFeatures().getArray();

        if (selectedFeatures?.length) {
            this.notifyObservers(
                this.isPureLassoSelectTool ? TOOL_EVENT.SELECT_FEATURES : TOOL_EVENT.TAGS_SELECT_FEATURES,
                selectedFeatures
            );
        }
    };

    /**
     * Get geojson from openlayers feature
     * @param {Feature} feature
     * @returns Geojson
     */
    getGeojsonByFeature(feature: $TSFixMe) {
        return outputMap.getGeojsonByFeature(feature);
    }

    handleTranslateEnd = (e: $TSFixMe) => {
        e.features.forEach((feature: $TSFixMe) => {
            const geom = feature.getGeometry();

            // handling out_of_extent case
            const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
                geom,
                boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
            });

            if (is_out_of_extent) {
                this.lastFeatures.forEach((lastFeature: $TSFixMe) => {
                    if (
                        lastFeature.get('layerId') === feature.get('layerId') &&
                        lastFeature.get('id') === feature.get('id')
                    ) {
                        feature.setGeometry(lastFeature.getGeometry());
                    }
                });
            } else {
                const layerId = feature.get('layerId');
                // Push layer in tracker
                layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
            }
        });
        if (layerTracker.getArray().length) {
            this.notifyObservers(TOOL_EVENT.MOVE_FEATURE);
        }
    };

    /**
     * Off the tool
     */
    off() {
        this.clearDrawing();
        this.mapObj.map.removeInteraction(this.select);
        this.mapObj.removeLayer(this.layer);
        this.mapObj.map.removeInteraction(this.draw);
        if (this.translate) {
            this.mapObj.map.removeInteraction(this.translate);
            this.translate.un('translateend', this.handleTranslateEnd);
        }
        this.lotFeature = null;
        this.bpLotExtent = null;
        this.lastFeatures = [];
        document.removeEventListener('keydown', this.onKeyPress);
        this.notifyObservers(
            this.isPureLassoSelectTool ? TOOL_EVENT.SELECT_FEATURES : TOOL_EVENT.CLEAR_TAGS_SELECTION,
            []
        );
    }
}

export default LassoSelect;
