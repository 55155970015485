import { captureException } from '@sentry/react';

import {
    allowedCountryCodes,
    COUNTRY_CODE,
    FILE_PANEL_WIDTH,
    MEASUREMENT_PANEL_WIDTH,
    GEOMETRY_TYPE_ENUM,
    IMAGERY_DEFAULT_DATE,
    REQUEST_STATUS_ENUM,
    TIMELINE_COMPONENTS_INDEX,
    TOOLS_ID,
    USER_OS
} from '../Constants/Constant';
import { HOLES_SUCCESS, PARCEL_UPDATE_SUCCESS } from '../Constants/Messages';
import { storage } from '../Stores/AppStore';
import { useOutputData } from '../Stores/Output';
import { useDevice } from '../Stores/Device';
import { useRequest } from '../Stores/Request';
import { roundNum } from './pureHelpers';

/**
 * @function
 * @description Download a file from URL
 * @param {String} url URL of file
 * @param {String} name Name of file while download (Optional)
 */

export const downloadFileWithUrl = function downloadFileWithUrl(url: $TSFixMe, name: $TSFixMe) {
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

/**
 * @function
 * @description Converst a object to array in following format
 * obj = {1: 'test', 2: 'test 2'}
 * return [{id: 1, value: 'test'}, {id: 2, value: 'test 2'}]
 * @param {Object} object Source Object
 */
export const objectToArray = (obj: $TSFixMe, invert = false) => {
    return Object.entries(obj).map(([key, value]) => ({
        id: invert ? value : key,
        value: invert ? key : value
    }));
};

/**
 * @function
 * @description Get parameter from URL
 * @param {String} url URL
 * @param {String} _name Parameter name to be get
 */
export function getParameterFromUrl(_name: $TSFixMe) {
    const url = window.location.href;
    const name = _name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp(`[?&#]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * @function
 * @description Use to reverse a Object
 * @param {Object} object Source Object
 */
export const reverseObj = (object: $TSFixMe) => {
    return Object.fromEntries(Object.entries(object || {}).reverse());
};

/**
 * @function
 * @description Get date string from timestamp
 * @param {int} timestamp timestamp
 */
export const getDateString = ({
    timestamp,
    longMonth = false,
    dateWithSuffix = false,
    withTime = false
}: {
    timestamp: any;
    longMonth?: boolean;
    dateWithSuffix?: boolean;
    withTime?: boolean;
}) => {
    const date = new Date(timestamp);
    const month = date.toLocaleString('default', { month: longMonth ? 'long' : 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}${dateWithSuffix ? engOrdinalSuffix(date) : ''} ${month}, ${year} ${
        withTime ? `at ${hours}:${minutes}` : ''
    }`;
};
export const engOrdinalSuffix = (dt: $TSFixMe) => {
    const day = dt.getDate();

    if (day % 10 === 1 && day !== 11) {
        return 'st';
    } else if (day % 10 === 2 && day !== 12) {
        return 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        return 'rd';
    } else {
        return 'th';
    }
};

/**
 * @function
 * @description Convert number to comma separated number
 * @param {int} number number
 */
export const formatCommaNumber = (number: $TSFixMe) => {
    if (number) {
        return number.toLocaleString('en-US');
    }
    return number;
};

/**
 * @function
 * @description Copy a value to clipboard
 * @param {string} val value
 */
export const copyToClipboard = async (val: $TSFixMe) => {
    return navigator.clipboard.writeText(val);
};

/**
 * @function
 * @description convert area into acre
 * @param {int} area value
 */
export const areaInAcres = (area: $TSFixMe, isImperialSystem: $TSFixMe) => {
    if (isImperialSystem) {
        return roundNum(area / 43560, 1);
    }

    return roundNum(area / 4047, 1);
};

/**
 * @function
 * @description check for mobile device
 */
export const isMobileDevice = () => {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    return toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem);
    });
};

/**
 * @function
 * @description get stored value from localstorage at {key} if {value} is empty or set {value} at {key}
 * in localstorage and return {value}
 * @param {String} key key name
 * @param {any} value value to be stored if not yet stored
 * @param {String} prefix any prefix to be added to key name
 */
export const getOrCreateLocStg = function getOrCreateLocStg(key: string, value?: any, prefix = '') {
    const storageKey = prefix + key;
    const oldJsonValue = localStorage.getItem(storageKey);

    if (value === undefined) {
        try {
            return oldJsonValue ? JSON.parse(oldJsonValue) : undefined;
        } catch (err) {
            captureException(err);
            return undefined;
        }
    }

    const jsonValue = JSON.stringify(value);
    let oldValue;

    try {
        oldValue = JSON.parse(oldJsonValue || 'null');
    } catch (err) {
        captureException(err);
    }

    if (oldJsonValue !== jsonValue) {
        localStorage.setItem(storageKey, jsonValue);
    }

    return value !== undefined ? value : oldValue;
};

export const arrayToObject = (arr: $TSFixMe, key = 'id') =>
    arr.reduce((acc: $TSFixMe, val: $TSFixMe) => {
        acc[val[key]] = val;
        return acc;
    }, {});
/**
 * @function
 * @description checks for special char
 * @param {String} key key name
 */

export const validateSpecialCharacter = function validateSpecialCharacter(name: $TSFixMe) {
    const regex = /^[a-zA-Z0-9-_ ]+$/;
    if (!regex.test(name)) {
        return false;
    }
    return true;
};

export const validateFeatureNameLength = function validateFeatureNameLength(name: $TSFixMe, isBlueprint = false) {
    if (isBlueprint) {
        return name.length <= 200;
    }
    return name.length <= 31;
};

export const changeMapCursor = function changeMapCursor(flag: $TSFixMe, yesCursor = 'pointer', noCursor = 'default') {
    const map = document.getElementById('map');
    if (!map) return;
    map.style.cursor = flag ? yesCursor : noCursor;
};

/**
 * @description parcel and non output layers that are not allowed to higlight or edit from other tools
 * @param {String} name
 * @returns {boolean}
 */

export const isRequestStatusInProgress = (status: $TSFixMe) =>
    [REQUEST_STATUS_ENUM.IN_PROGRESS, REQUEST_STATUS_ENUM.INVESTIGATING, REQUEST_STATUS_ENUM.RESUBMIT].includes(
        parseInt(status, 10)
    );
/**
 * @description check whether app running in iframe
 * @returns {boolean}
 */

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const postMessageToReferrer = (data: $TSFixMe, targetOrigin = '*') => {
    if (window.parent) window.parent.postMessage(data, targetOrigin);
};

export const getFirstLastName = (name: $TSFixMe) => {
    if (typeof name !== 'string') return [];

    const nameArr = name.split(' ');
    let firstName = name;
    let lastName;
    if (nameArr.length !== 1) {
        firstName = nameArr.slice(0, -1).join(' ');
        lastName = nameArr.slice(-1).join(' ');
    }
    return [firstName, lastName];
};
export const getFullName = (firstName: $TSFixMe, lastName: $TSFixMe) => {
    // It is assumed that first name will always be available
    if (!lastName) return firstName;
    return `${firstName} ${lastName}`;
};
export function getTimeFormat(sec: $TSFixMe) {
    let formattedTime = '';
    if (sec < 60) {
        formattedTime = `${sec} sec`;
    } else if (sec < 3600) {
        const min = Math.ceil(sec / 60);
        formattedTime = `${min} min`;
    } else {
        const hour = Math.ceil(sec / 3600);
        const abbr = hour > 1 ? 's' : '';
        formattedTime = `${hour} hour${abbr}`;
    }
    return formattedTime;
}

export function formatRequestETA(remainingTime: $TSFixMe) {
    if (remainingTime > 60) {
        const SECONDS_IN_HOUR = 60 * 60;
        const hours = Math.floor(remainingTime / SECONDS_IN_HOUR);
        const min = Math.floor((remainingTime - hours * SECONDS_IN_HOUR) / 60);
        const minsWithZero = String(min).padStart(2, '0');
        if (remainingTime > SECONDS_IN_HOUR) {
            const hoursWithZero = String(hours).padStart(2, '0');
            return `${hoursWithZero}:${minsWithZero} ${hours > 1 ? 'hrs' : 'hr'}`;
        }
        return `${minsWithZero}:00 min`;
    }
    return '';
}

export function secondsToHHMM(_secs: $TSFixMe) {
    if (!_secs) return '';

    const secs = Number(_secs);

    const hh = Math.floor(secs / 3600);
    const mm = Math.floor((secs % 3600) / 60);

    const hhDisplay = hh > 0 ? hh + (hh === 1 ? ' hour, ' : ' hours, ') : '';
    const mmDisplay = mm > 0 ? mm + (mm === 1 ? ' minute' : ' minutes') : '';

    if (!mmDisplay) return hhDisplay.substring(0, hhDisplay.length - 2);
    return hhDisplay + mmDisplay;
}

export function gaMetrics(category: $TSFixMe) {
    // @ts-expect-error TS(2304): Cannot find name 'gtag'.
    if (typeof gtag === 'function') {
        // @ts-expect-error TS(2304): Cannot find name 'gtag'.
        // eslint-disable-next-line no-undef
        gtag('event', window.location.href, {
            event_category: category,
            event_label: storage.get('emailId')
        });
    }
}

export const setLayerGroupVisibility = (_layers: $TSFixMe, visible: $TSFixMe) => {
    let layers = null;
    if (!Array.isArray(_layers)) {
        layers = [_layers];
    } else {
        layers = _layers;
    }
    const layerVisibility = useRequest.getState()?.layerVisibility;

    layers.forEach(layer => {
        layerVisibility[layer] = visible;
        return null;
    });
    useRequest.getState()?.dispatch({ type: 'SET_LAYER_VISIBILTY', payload: layerVisibility });
};

export const getRequestData = () => {
    return useRequest.getState()?.requestData || {};
};

export const getOutputData = () => {
    return useOutputData.getState()?.outputData || {};
};

/**
 *
 * @param {Array} arr An array of object
 * @param {String} key Key in the object
 * @returns An array of objects with unique [key] properties
 */
export function getUniqueListByKey(arr = [], key: $TSFixMe) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
}

/**
 * @param {integer} min range minimum
 * @param {integer} max range maxmum
 * @returns a random integer between min and max (both inclusive)
 */
export function getRandomInt(min: $TSFixMe, max: $TSFixMe) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * @returns colors cyclically
 */
export function getNextColor(index: $TSFixMe) {
    const colors = ['#98FFB2', '#FF0033', '#082567', '#CC00FF'];
    return colors[index % colors.length];
}

export const gaOnly = (el: $TSFixMe) => (Array.isArray(el) ? el : []);

export const openLinkInNewTab = (link: $TSFixMe) => {
    if (!link) return;
    window.open(link, '_blank');
};

export const padWithZero = (str: $TSFixMe, n = 2) => {
    return String(str).padStart(n, '0');
};

export const getAddressOrLatLon = (input: $TSFixMe) => {
    if (input && input.aoi_geojson) {
        if (input.address) {
            return input.address;
        }
        if (input.lat && input.lon) {
            return `${input.lat}, ${input.lon}`;
        }
    }
    return '';
};

/**
 * @param {Array} arr Array of strings
 * @returns a comma separated string of elements.
 * For example ['Element A', 'Element B', 'Element C'] will be returned as 'Element A, Element B, and Element C'
 */
export const arrayToCommaString = (arr: $TSFixMe) => {
    if (!Array.isArray(arr) || arr.length === 0) return '';
    const newArr = [...arr];
    if (newArr.length === 1) return newArr[0];
    if (newArr.length === 2) return `${newArr[0]} and ${newArr[1]}`;
    const lastElement = newArr[newArr.length - 1];
    newArr[newArr.length - 1] = `and ${lastElement}`;
    return newArr.join(', ');
};

export const isValidPostalCode = (postalCode: string, countryCode: string) => {
    if (!postalCode || !countryCode) return false;
    let postalCodeRegex;
    switch (countryCode) {
        case 'US':
            postalCodeRegex = /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/;
            break;
        case 'CA':
            postalCodeRegex = /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/;
            break;
        default:
            postalCodeRegex = /^(?:[A-Z0-9]+([- ]?[A-Z0-9]+)*)?$/;
    }
    return postalCodeRegex.test(postalCode);
};
export const getAerialTakeoffTimelineIndex = (res: $TSFixMe) => {
    let timeLineIndex = null;
    if (res.status === REQUEST_STATUS_ENUM.DRAFT) {
        timeLineIndex = res.input?.aoi_geojson
            ? TIMELINE_COMPONENTS_INDEX.PROPERTY_VIEW
            : TIMELINE_COMPONENTS_INDEX.NOT_MY_PROPERTY;
    } else if (res.status === REQUEST_STATUS_ENUM.IN_PROGRESS || res.status === REQUEST_STATUS_ENUM.INVESTIGATING) {
        timeLineIndex = TIMELINE_COMPONENTS_INDEX.WAITING_PANEL;
    } else if (res.status === REQUEST_STATUS_ENUM.COMPLETED || res.status === REQUEST_STATUS_ENUM.RESUBMIT) {
        timeLineIndex = TIMELINE_COMPONENTS_INDEX.MEASUREMENTS;
    } else if (res.status === REQUEST_STATUS_ENUM.FAILED) {
        timeLineIndex = TIMELINE_COMPONENTS_INDEX.FAILED;
    } else if (res.status === REQUEST_STATUS_ENUM.CANCELLED) {
        timeLineIndex = TIMELINE_COMPONENTS_INDEX.CANCELLED;
    } else if (res.status === REQUEST_STATUS_ENUM.QUEUED) {
        timeLineIndex = TIMELINE_COMPONENTS_INDEX.QUEUED;
    }
    return timeLineIndex;
};

// Layer style include color, visbility,  opacity, width etc.
export const disableEditingLayerStyle = ({ liteUser, isTakeOffView }: $TSFixMe) => {
    if (isTakeOffView) return true;
    // Allow user to edit layer style if request is opened in sharedView or it's a lite user
    // @ts-expect-error TS(2339): Property 'isSharedView' does not exist on type 'Wi... Remove this comment to see the full error message
    if (!!window.isSharedView || !!liteUser) return false;
    return null;
};

export const calculateBPLength = ({ dpi, scale, length }: $TSFixMe) => {
    const px_to_mm = 25.4 / dpi;
    return length * scale * px_to_mm * 0.001;
};

export const calculateBPArea = ({ dpi, scale, area }: $TSFixMe) => {
    const px_to_mm = 25.4 / dpi;
    return area * scale * scale * px_to_mm * px_to_mm * 0.001 * 0.001;
};

// It returns all files that has at least one workable sheet
export const filterBPWorkableFiles = (files: $TSFixMe, isWorkablePredicate: $TSFixMe) => {
    if (!files) return null;
    const fileFilter = (file: $TSFixMe) => file.sheets?.some(isWorkablePredicate);

    const fileTransform = (file: $TSFixMe) => {
        const workableSheets = file.sheets?.filter(isWorkablePredicate) || [];
        return { ...file, sheets: workableSheets };
    };

    return files?.filter(fileFilter)?.map(fileTransform);
};

// It returns all files that has at least one workable sheet
export const findFileByPage = (files: $TSFixMe, page: $TSFixMe) => {
    if (!files || !page) return {};
    return files?.find((file: $TSFixMe) => file.sheets?.some((sheet: $TSFixMe) => sheet.id === page.id)) || {};
};

// Given the list of files it returns the first page that it encounters
// If first file doesn't have any page, it will move on to second file and so on until it finds a page
// If no page is found then it returns undefined
export const getFirstPage = (files: $TSFixMe) => {
    if (!files) return null;
    for (let i = 0; i < files.length; ++i) {
        const file = files[i];
        if (file.sheets.length > 0) {
            return file.sheets[0];
        }
    }
    return null;
};

// It converts a blueprint route if it is not already
export const convertToBlueprintRoute = (route: $TSFixMe) => {
    if (route.startsWith('/blueprint/')) return route;
    return `/blueprint${route}`;
};

// It checks if the two strings are same
export const areStringsSame = (str1: $TSFixMe, str2: $TSFixMe) => {
    if (!str1 || !str2) return false;

    return str1.toUpperCase() === str2.toUpperCase();
};
export const formatVintageDate = (timestamp: $TSFixMe) => {
    const date = new Date(timestamp || IMAGERY_DEFAULT_DATE);
    if (Number.isNaN(Number(date))) return null;
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear().toString().slice(-2);
    return `${month}' ${year}`;
};

export const areExclusiveOptionalFeaturesSelected = (featureExclusivity: $TSFixMe) => {
    return Object.values(featureExclusivity).some((feature: $TSFixMe) => feature.length > 1);
};

export function isObjectEmpty(obj: $TSFixMe) {
    return !obj || Object.keys(obj).length === 0;
}

export const updatePageInFiles = (files: $TSFixMe, updatedPage: $TSFixMe) => {
    if (!files) return [];

    return files.map((file: $TSFixMe) => ({
        ...file,
        sheets: file.sheets.map((sheet: $TSFixMe) => (sheet.id === updatedPage.id ? updatedPage : sheet))
    }));
};

// Calculates and returns usage summary page total takeoffs/workable-sheets and total costs
export const totalTakeoffCount = (items: $TSFixMe) => {
    return items.reduce((sum: $TSFixMe, item: $TSFixMe) => sum + (item?.takeoff_count || 0), 0);
};

export const totalTakeoffCost = (items: $TSFixMe) => {
    return items.reduce((sum: $TSFixMe, item: $TSFixMe) => sum + (item?.takeoff_cost || 0), 0);
};

export const totalWorkableSheetCount = (items: $TSFixMe) => {
    return items.reduce((sum: $TSFixMe, item: $TSFixMe) => sum + (item?.workable_sheets || 0), 0);
};
export const totalAddendumSheetCount = (items: $TSFixMe) => {
    return items.reduce((sum: $TSFixMe, item: $TSFixMe) => sum + (item?.addendum_sheets || 0), 0);
};
export const lengthMeasurementUnit = (isImperialSystem: $TSFixMe) => (isImperialSystem ? 'ft' : 'm');
export const areaMeasurementUnit = (isImperialSystem: $TSFixMe) => (isImperialSystem ? 'sqft' : 'sqm');
export const volumeMeasurementUnit = (isImperialSystem: $TSFixMe) => (isImperialSystem ? 'cuyd' : 'm3');
export const weightMeasurementUnit = (isImperialSystem: $TSFixMe) => (isImperialSystem ? 'lb' : 'kg');
export const weightTonMeasurementUnit = (isImperialSystem: $TSFixMe) => (isImperialSystem ? 'tn' : 't');

export const findCountryCode = (results: $TSFixMe) => {
    let countryCode = '';
    for (let i = 0; i < results[0].address_components.length; i++) {
        const component = results[0].address_components[i];
        if (component.types.includes('country')) {
            countryCode = component.short_name;
            break;
        }
    }

    return countryCode;
};

export const getCountryCode = (record: $TSFixMe, defaultCountryCode: $TSFixMe) => {
    let countryCode = COUNTRY_CODE.USA;
    if (record?.country) {
        countryCode = record?.country;
    } else if (allowedCountryCodes.includes(defaultCountryCode)) {
        countryCode = defaultCountryCode;
    }
    return countryCode;
};

/// RGB to hex code converter
export function rgbToHex(r: $TSFixMe, g: $TSFixMe, b: $TSFixMe) {
    /// converts a number to base 16 number
    function componentToHex(c: $TSFixMe) {
        const hex = Number(c).toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    }

    const result = `#${componentToHex(r || 0)}${componentToHex(g || 0)}${componentToHex(b || 0)}`;
    return result;
}

export const hexToRgbA = (hex: $TSFixMe, opacity: $TSFixMe) => {
    let c = hex.substring(1).split('');
    if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;

    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opacity})`;
};

export function isNumericalLayer(layer: $TSFixMe) {
    if (!layer) return false;
    const props = layer.getProperties();
    const geometryType = props?.layerData?.feature?.geometry_type;
    return geometryType === GEOMETRY_TYPE_ENUM.NUMERICAL;
}
export function isTypicalLayer(layer: $TSFixMe) {
    if (!layer) return false;
    const props = layer.getProperties();
    const geometryType = props?.layerData?.feature?.geometry_type;
    return geometryType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT;
}
export function clearProperties(feature: $TSFixMe, propertiesToCheck: $TSFixMe) {
    const featureProperties = feature.getProperties();

    // eslint-disable-next-line no-restricted-syntax
    for (const property of propertiesToCheck) {
        if (property in featureProperties) {
            feature.setProperties({ [property]: null });
        }
    }
}

export function getNumericalPopupInfo(map: $TSFixMe, feature: $TSFixMe) {
    const { id, layerId } = feature.getProperties();
    const coordinate = feature.getGeometry().getCoordinates();
    const pixel = map.getPixelFromCoordinate(coordinate);
    const targetElement = map.getTargetElement();
    const targetOffset = targetElement.getBoundingClientRect();
    const pageX = pixel[0] + targetOffset.left + 10;
    const pageY = pixel[1] + targetOffset.top + 10;
    return { pageX, pageY, id, layerId };
}

export const getParcelSaveMsg = (activeToolId: $TSFixMe) => {
    switch (activeToolId) {
        case TOOLS_ID.ADD_PARCEL:
        case TOOLS_ID.EDIT_PARCEL:
        case TOOLS_ID.DELETE_PARCEL:
            return PARCEL_UPDATE_SUCCESS;
        case TOOLS_ID.DRAW_HOLE:
            return HOLES_SUCCESS;
        default:
            return '';
    }
};

export const getMeasurementPanelBtnLeftStyle = (collapsed: $TSFixMe) => {
    // -15 offset for btnLeft is used to position the button in the middle of the right border of the panel.
    return collapsed ? 0 : MEASUREMENT_PANEL_WIDTH - 15;
};

export const getFilePanelBtnLeftStyle = (measurementPanelCollapsed: $TSFixMe, filePanelCollapsed: $TSFixMe) => {
    // 0 for btnLeft is used to position the button at the left edge of the screen.
    // -15 offset for btnLeft is used to position the button in the middle of the right border of the panel.
    // -52 offset for btnLeft is used in case of btn contains label also.
    if (measurementPanelCollapsed) return filePanelCollapsed ? 0 : FILE_PANEL_WIDTH - 15;
    return filePanelCollapsed ? MEASUREMENT_PANEL_WIDTH - 52 : MEASUREMENT_PANEL_WIDTH + FILE_PANEL_WIDTH - 15;
};

export const adjustOverlayPosition = ({ pageX = 0, pageY = 0, overlayWidth = 0, overlayHeight = 0 }) => {
    const OFFSET = 10; // Offset to add some space between the overlay and the screen edges

    // Minimum left/top position of the overlay
    const minLeft = 0 + OFFSET;
    const minTop = 0 + OFFSET;

    const SCREEN_WIDTH = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const SCREEN_HEIGHT = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    // Maximum right/bottom position of the overlay
    const maxRight = SCREEN_WIDTH - overlayWidth - OFFSET;
    const maxBottom = SCREEN_HEIGHT - overlayHeight - OFFSET;

    // Initial left/top position of overlay
    let leftPosition = pageX;
    let topPosition = pageY;

    // Check if the overlay is going beyond the minimum left position
    if (pageX < minLeft) {
        leftPosition = minLeft;
    }

    // Check if the overlay is going beyond the minimum top position
    if (pageY < minTop) {
        topPosition = minTop;
    }

    // Check if the overlay is going beyond the maximum right position
    if (pageX > maxRight) {
        leftPosition = maxRight;
    }

    // Check if the overlay is going beyond the maximum bottom position
    if (pageY > maxBottom) {
        topPosition = maxBottom;
    }

    // Return the adjusted position of the overlay
    return [Math.round(leftPosition), Math.round(topPosition)];
};

export function hasDependencyOnSelectedFeatures(toolId: $TSFixMe) {
    return [
        TOOLS_ID.ROTATE_CLOCKWISE,
        TOOLS_ID.ROTATE_COUNTER_CLOCKWISE,
        TOOLS_ID.FLIP_HORIZONTAL,
        TOOLS_ID.FLIP_VERTICAL,
        TOOLS_ID.CUT_TOOL,
        TOOLS_ID.COPY_TOOL
    ].includes(toolId);
}

export const getFileSize = (fileUrl: $TSFixMe) => {
    return fetch(fileUrl, {
        method: 'HEAD'
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.headers.get('content-length');
        })
        .catch(error => {
            throw error;
        });
};

export const removeFileExtension = (fileName: $TSFixMe) => fileName.replace(/\.[^/.]+$/, '');

export const emptySelectedFeaturesArray = () => {
    useRequest.getState()?.dispatch({ type: 'SET_SELECTED_FEATURES', payload: [] });
};

export const emptyTagsSelectedFeaturesArray = () => {
    useRequest.getState()?.dispatch({ type: 'SET_TAGS_SELECTED_FEATURES', payload: [] });
};

export const emptyTagsSelectedLayerArray = () => {
    useRequest.getState()?.dispatch({ type: 'SET_TAGS_SELECTED_LAYERS', payload: [] });
};

export const detectUserOS = () => {
    const { platform } = navigator;
    const { WIN, MAC, LINUX, IOS, ANDROID, UNKNOWN } = USER_OS || {};
    if (platform.indexOf('Win') !== -1) return WIN;
    if (platform.indexOf('Mac') !== -1) return MAC;
    if (platform.indexOf('Linux') !== -1) return LINUX;
    if (platform.indexOf('iPhone') !== -1 || platform.indexOf('iPad') !== -1) return IOS;
    if (platform.indexOf('Android') !== -1) return ANDROID;
    return UNKNOWN;
};

export const getToolShortcut = ({ winKey, macKey, letter }: $TSFixMe) => {
    const userOS = useDevice.getState()?.userOS;
    const isMac = userOS === USER_OS.MAC;
    return `${isMac ? macKey : winKey} + ${letter}`;
};

export const allKeysHaveValues = (obj: $TSFixMe) => {
    if (!Object.keys(obj).length) return false;

    if (Object.values(obj).every((value: $TSFixMe) => value)) {
        return true;
    }
    return false;
};

export const containsIgnoreCase = (mainString = '', query = '') =>
    mainString.toLowerCase().includes(query.toLowerCase());

export const debounce = (cb: $TSFixMe, delay = 500) => {
    let timer: $TSFixMe;
    return (...args: $TSFixMe[]) => {
        if (timer) {
            clearInterval(timer);
        }
        timer = setTimeout(() => cb(...args), delay);
    };
};

export const passwordValidation = (password: $TSFixMe) => {
    let passwordError;

    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}$/;
    if (!passwordRegex.test(password)) {
        passwordError =
            'Password must have at least one capital letter, one special character, one number, and be at least 8 characters long.';
    }

    return passwordError;
};

export const openShareLink = ({ sharedId, isConstructionUsageMode = false }: $TSFixMe) => {
    const sharedViewUrl = `${isConstructionUsageMode ? '/blueprint' : ''}/shared-view?id=${sharedId}`;
    const sharedLink = window.location.origin + sharedViewUrl;
    openLinkInNewTab(sharedLink);
};

export const mapGroupData = (
    groups: $TSFixMe,
    setAllFeatures: $TSFixMe,
    groupId = null,
    groupData = null,
    setGroupData = null
) => {
    if (groups) {
        const mappedFeatures = groups.reduce((acc: $TSFixMe, group: $TSFixMe) => {
            const singleGroup = group.features.map((feat: $TSFixMe) => {
                const data = {
                    label: feat?.name || feat?.feature?.name,
                    value: feat?.id
                };
                // Group name is present for ungrouped group as well so we need to check if the group id is present
                if (group?.group_id) {
                    // @ts-expect-error TS(2339): Property 'groupName' does not exist on type '{ lab... Remove this comment to see the full error message
                    data.groupName = group.group_name;
                }
                if (groupId && group.group_id === groupId) {
                    // @ts-expect-error TS(2531): Object is possibly 'null'.
                    if (!groupData.includes(feat.id)) {
                        // @ts-expect-error TS(2721): Cannot invoke an object which is possibly 'null'.
                        setGroupData((prev: $TSFixMe) => [...prev, feat.id]);
                    }
                }
                return data;
            });

            return acc.concat(singleGroup);
        }, []);
        setAllFeatures([...mappedFeatures]);
    }
};

export const updateTakeoffGroupData = (
    groupId: $TSFixMe,
    groupData: $TSFixMe,
    res: $TSFixMe,
    conflictingFeatures: $TSFixMe
) => {
    if (groupId) {
        groupData.features = groupData?.features.map((feat: $TSFixMe) => {
            if (feat?.group?.id === groupId || !feat.group) {
                if (res.feature_ids && res.feature_ids.includes(feat.id)) {
                    return {
                        ...feat,
                        group: {
                            id: res.id,
                            name: res.name
                        }
                    };
                } else {
                    return {
                        ...feat,
                        group: null
                    };
                }
            }
            return feat;
        });
        const groupIndex = groupData?.groups?.findIndex((group: $TSFixMe) => group.id === groupId);
        groupData.groups[groupIndex] = res;
    } else {
        groupData.features = groupData?.features.map((feat: $TSFixMe) => {
            if (res.feature_ids && res.feature_ids.includes(feat.id)) {
                return {
                    ...feat,
                    group: {
                        id: res.id,
                        name: res.name
                    }
                };
            }
            return feat;
        });
        groupData?.groups.push(res);
    }

    if (conflictingFeatures.length) {
        groupData.groups.forEach((group: $TSFixMe) => {
            if (group.id !== res.id && group?.feature_ids) {
                group.feature_ids = group.feature_ids.filter((id: $TSFixMe) => !conflictingFeatures.includes(id));
            }
        });
    }

    return groupData;
};

export const updateOutputGroupData = (
    groupId: $TSFixMe,
    outputs: $TSFixMe,
    groups: $TSFixMe,
    res: $TSFixMe,
    conflictingFeatures: $TSFixMe
) => {
    let updatedOutputs;
    if (groupId) {
        updatedOutputs = outputs.map((output: $TSFixMe) => {
            if (output?.group?.id === groupId || !output.group) {
                if (res?.output_ids && res?.output_ids.includes(output.id)) {
                    return {
                        ...output,
                        group: {
                            id: res.id,
                            name: res.name
                        }
                    };
                } else {
                    return {
                        ...output,
                        group: null
                    };
                }
            }
            return output;
        });
        const groupIndex = groups.findIndex((group: $TSFixMe) => group.id === groupId);
        groups[groupIndex] = res;
    } else {
        updatedOutputs = outputs.map((output: $TSFixMe) => {
            if (res.output_ids && res.output_ids.includes(output.id)) {
                return {
                    ...output,
                    group: {
                        id: res.id,
                        name: res.name
                    }
                };
            }
            return output;
        });
        groups.push(res);
    }

    if (conflictingFeatures.length) {
        groups.forEach((group: $TSFixMe) => {
            if (group.id !== res.id && group?.output_ids) {
                group.output_ids = group.output_ids.filter((id: $TSFixMe) => !conflictingFeatures.includes(id));
            }
        });
    }

    return [updatedOutputs, groups];
};

export const getSuffix = (day: number) => {
    if (day % 10 === 1 && day !== 11) {
        return 'st';
    } else if (day % 10 === 2 && day !== 12) {
        return 'nd';
    } else if (day % 10 === 3 && day !== 13) {
        return 'rd';
    } else {
        return 'th';
    }
};
