import { captureException, setExtra } from '@sentry/react';
import cloneDeep from 'lodash/cloneDeep';

import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import { fromLonLat, transformExtent } from 'ol/proj';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { Overlay } from 'ol';
import Fill from 'ol/style/Fill';
import Circle from 'ol/style/Circle';
import { Icon, RegularShape, Text } from 'ol/style';
import { asArray } from 'ol/color';
import Feature from 'ol/Feature';
import Select from 'ol/interaction/Select';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'ol-e... Remove this comment to see the full error message
import GeoImageSource from 'ol-ext/source/GeoImage';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorLayer from 'ol/layer/Vector';
import { extend } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { fromExtent } from 'ol/geom/Polygon';
import { featureCollection as turfFeatureCollection, feature as turfFeature } from '@turf/helpers';
import transformRotate from '@turf/transform-rotate';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';

import { isPlainObject } from 'lodash';
import { HIGHLIGHT_STYLE_NUMERICAL, ZONAL_STYLE, getHighlightZoneStyle, highlightStyle } from './MapBase';
import {
    filterBlacklistPropertiesInFeatures,
    isValidPolygon,
    getNewLayerWidth,
    createPattern
} from '../../Utils/olutils';
import { editParcel, layerTracker, tileImagery, toolController } from './MapInit';
import {
    adjustOverlayPosition,
    changeMapCursor,
    debounce,
    formatCommaNumber,
    getOutputData,
    getRequestData,
    isNumericalLayer,
    rgbToHex
} from '../../Utils/HelperFunctions';
import { interpolate, patchAPI, postAPI } from '../../Utils/ApiCalls';
import { ADD_BLUEPRINT_FEATURE_TAGS, AOI_STYLE, REQUEST, STYLE, UPDATE_ARROWS, ZONE_STYLE } from '../../Constants/Urls';
import {
    BP_PREFIX,
    GEOMETRY_TYPE_ENUM,
    LAYER_INDEX,
    MAP_LAYERS,
    MAP_ROTATION,
    OBLIQUE_ROTATION,
    PARCEL_DEFAULT_STYLE,
    REQUEST_STATUS_ENUM,
    SIDE_VIEWS_MAP,
    TOOLS_ID,
    IMPERIAL_ATTRIBUTE_UNIT_NAMES,
    METRIC_ATTRIBUTE_UNIT_NAMES,
    METRIC_ATTRIBUTE_UNIT_TYPES,
    IMPERIAL_ATTRIBUTE_UNIT_TYPES,
    ZONE_ACTIONS,
    POINT_SHAPES,
    POINT_SHAPES_ENUM,
    FILL_PATTERNS_ENUM,
    MEASUREMENT_VIEW_ENUM,
    NETWORK_CALL_STATUS,
    TYPICAL_ICON
} from '../../Constants/Constant';
import { getVisibleGeoms, mergeFeatAndGeomTags } from '../../Components/tags/helpers';
import { useTags } from '../../Stores/Tags';
import { useDevice } from '../../Stores/Device';
import { useRequest } from '../../Stores/Request';
import {
    ATTRIBUTE_UNITS_VALUE,
    METRIC_ATTRIBUTE_UNIT_ENUM,
    ASSEMBLY_UNIT_NAMES_V2,
    ATTRIBUTE_UNITS_ENUM
} from '../../Constants/units';
import { roundNum, convertToFixedDecimal } from '../../Utils/pureHelpers';
import { useContextMenu } from '../../Stores/ContextMenu';
import { TOOL_EVENT } from '../Output/Toolbar/ToolController';
import RequestDataInterface from '../../types/requestData';
import OutputDataInterface from '../../types/outputData';

class OutputMap {
    attributeOverlay: $TSFixMe;

    bpLotExtent: $TSFixMe;

    debouncedFn: $TSFixMe;

    hideMeasurements: $TSFixMe;

    highlightInteraction: $TSFixMe;

    highlightZoneInteraction: $TSFixMe;

    invalidSpace: $TSFixMe;

    isImperialSystem: $TSFixMe;

    mapObj: $TSFixMe;

    pageTagElement: $TSFixMe;

    parcelLayer: $TSFixMe;

    ptrCoord: $TSFixMe;

    timeout: $TSFixMe;

    toggleAllLayersState: $TSFixMe;

    showTooltip: $TSFixMe;

    attributeMapper: $TSFixMe;

    measurementMapper: $TSFixMe;

    typicalSheet: $TSFixMe;

    constructor(mapObj: $TSFixMe) {
        this.mapObj = mapObj;
        this.parcelLayer = null;
        this.timeout = null;
        this.highlightInteraction = null;
        this.attributeOverlay = null;
        this.ptrCoord = null;
        this.isImperialSystem = false;
        this.pageTagElement = null;
        this.highlightZoneInteraction = null;
        this.debouncedFn = null;
        this.hideMeasurements = false;
        this.showTooltip = true;
        this.invalidSpace = false;
        this.bpLotExtent = null;
        this.attributeMapper = {};
        this.measurementMapper = {};
        this.typicalSheet = false;
    }

    init({ hideMeasurements = false, isManualBPTRequest = false, isImperialSystem = true, showTooltip = true } = {}) {
        const { isMobileDevice = false } = useDevice.getState()?.screen || {};
        this.mapObj.setMobileView(isMobileDevice);
        this.showTooltip = showTooltip;
        this.isImperialSystem = isImperialSystem;
        this.hideMeasurements = hideMeasurements;
        this.highlightInteraction = new Select({
            // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: () => ... Remove this comment to see the full error message
            wrapX: false,
            filter: () => false,
            condition: () => false,
            style: feature => {
                const layer = this.mapObj.getLayerById(feature.get('layerId'));
                return layer && isNumericalLayer(layer)
                    ? HIGHLIGHT_STYLE_NUMERICAL
                    : // @ts-expect-error TS(2322): Type 'FeatureLike' is not assignable to type 'null... Remove this comment to see the full error message
                      highlightStyle({ layer, feature });
            }
        });
        this.mapObj.map.addInteraction(this.highlightInteraction);

        this.highlightZoneInteraction = new Select({
            // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: () => ... Remove this comment to see the full error message
            wrapX: false,
            filter: () => false,
            condition: () => false,
            style: feat => {
                const layer = this.mapObj.getLayerById(feat?.get('layerId'));
                if (layer.get('name') === MAP_LAYERS.ZONE) {
                    return getHighlightZoneStyle(layer?.get('zoneName'));
                }
                return null;
            }
        });
        this.mapObj.map.addInteraction(this.highlightZoneInteraction);

        this.attributeOverlay = document.getElementById('attribute-overlay');

        this.pageTagElement = document.getElementById('inactive-sheet-label');

        if (this.pageTagElement) {
            this.pageTagElement.innerText = 'INACTIVE';
            this.pageTagElement.classList.add('inactive-sheet-label-style');
            this.pageTagElement.style.display = 'none';
            const pageTagOverlayElement = new Overlay({
                element: this.pageTagElement
            });
            pageTagOverlayElement.setPosition([30, -30]);
            pageTagOverlayElement.setPositioning('top-left');
            this.mapObj.map.addOverlay(pageTagOverlayElement);
        }

        this.typicalSheet = document.getElementById('typical-sheet');

        if (this.typicalSheet) {
            this.typicalSheet.innerText = 'TYPICAL';
            this.typicalSheet.classList.add('typical-sheet-style');
            this.typicalSheet.style.display = 'none';
            const pageTypicalOverlayElement = new Overlay({
                element: this.typicalSheet
            });
            pageTypicalOverlayElement.setPosition([30, -30]);
            pageTypicalOverlayElement.setPositioning('top-left');
            this.mapObj.map.addOverlay(pageTypicalOverlayElement);
        }

        // This is used to render the feature detail overlays present on the map when the user
        // hovers over them
        this.mapObj.map.on('pointermove', (evt: $TSFixMe) => {
            // if showTooltip is disabled in map configuration, then dont perform any operation to show tooltip
            if (!this.showTooltip) {
                return;
            }

            this.invalidSpace = !this.mapObj?.coordsExistsInParcel(
                evt.coordinate,
                this.mapObj.isBlueprintMap ? this.bpLotExtent : null
            );
            this.ptrCoord = evt.coordinate;
            const layer = this.mapObj.map.forEachFeatureAtPixel(evt.pixel, (_: $TSFixMe, _layer: $TSFixMe) => _layer, {
                checkWrapped: false
            });

            const activeTool = toolController.getActiveTool();

            // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
            if (activeTool?.toolId === TOOLS_ID.NOTES_TOOL) {
                changeMapCursor(true, 'crosshair', '');
                // @ts-expect-error TS(2339): Property 'activeTool' does not exist on type 'Wind... Remove this comment to see the full error message
            } else if (!window.activeTool) {
                changeMapCursor(Boolean(layer), 'pointer', '');
            }

            // @ts-expect-error TS(2339): Property 'activeTool' does not exist on type 'Wind... Remove this comment to see the full error message
            if (window.activeTool) return;

            const features = this.mapObj.map.getFeaturesAtPixel(evt.pixel, {
                checkWrapped: false
            });

            if (isManualBPTRequest) {
                const bpPageProps = this.mapObj.baseLayer?.getProperties() || {};
                this.hideMeasurements = this.hideMeasurements || !bpPageProps.bp_page_scale;
            }

            this.attributeOverlay.innerHTML = null;
            features.forEach((feature: $TSFixMe) => {
                const lyr = feature.get('isNote') ? layer : this.mapObj.getLayerById(feature.get('layerId'));
                // The overlay is shown only if both feature and layer exist and no tool is selected
                if (feature && lyr) {
                    const layerName = lyr.get('name');
                    let showOverlay = false;

                    if (layerName === MAP_LAYERS.OUTPUT) {
                        showOverlay = true;

                        const lyrData = lyr?.get('layerData') || {};
                        const { name: featureName, default_tags: featureTags } = lyrData?.feature || {};
                        const assemblies = lyrData?.assemblies || [];
                        const featureAttributes = lyrData?.attributes || [];
                        const featureAttributesMeasurement = lyrData?.attribute_based_measurements || [];

                        this.attributeOverlay.innerHTML += renderAttributeTable(
                            this.mapObj.isBlueprintMap,
                            feature.getProperties(),
                            featureTags,
                            featureName,
                            lyr.getStyle().getStroke().getColor(),
                            this.hideMeasurements,
                            this.isImperialSystem,
                            assemblies,
                            featureAttributes,
                            featureAttributesMeasurement,
                            this.attributeMapper,
                            this.measurementMapper
                        );
                    } else if (layerName === MAP_LAYERS.NOTES) {
                        showOverlay = true;
                        const { full_name, email } = feature?.get('noteData')?.user_details || {};
                        const ownerName = full_name || email || 'Anonymous';
                        this.attributeOverlay.innerHTML = renderOwnerNameOverlay(ownerName);
                    }

                    if (showOverlay) {
                        const { pageX, pageY } = evt.originalEvent;
                        const { offsetWidth: overlayWidth, offsetHeight: overlayHeight } = this.attributeOverlay;

                        const [leftPosition, topPosition] = adjustOverlayPosition({
                            pageX,
                            pageY,
                            overlayWidth,
                            overlayHeight
                        });

                        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
                        this.attributeOverlay.style.left = `${parseInt(leftPosition, 10) + 5}px`;
                        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
                        this.attributeOverlay.style.top = `${parseInt(topPosition, 10) + 5}px`;
                        this.attributeOverlay.style.display = 'flex';
                    }
                } else {
                    this.attributeOverlay.style.display = 'none';
                }
            });
        });

        this.mapObj.map.on('singleclick', () => {
            this.clearHighlightFeature();
        });

        this.mapObj.map.getViewport().addEventListener('contextmenu', (e: $TSFixMe) => {
            useContextMenu?.getState()?.onClose();
            const activeTool = toolController.getActiveTool();
            const isTakeOffView =
                // @ts-expect-error TS(2339): Property 'output_mode' does not exist on type '{}'... Remove this comment to see the full error message
                useRequest?.getState()?.requestData?.output_mode === MEASUREMENT_VIEW_ENUM.TAKEOFF_LEVEL;

            const showDefault =
                // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
                isTakeOffView || this.invalidSpace || (activeTool && activeTool?.toolId !== TOOLS_ID.SELECT_TOOL);

            e.preventDefault();

            if (showDefault) return;

            // @ts-expect-error TS(2531): Object is possibly 'null'.
            document.getElementById('attribute-overlay').style.display = 'none';
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            document.getElementById('notes-container').style.display = 'none';

            const coordinate = this.mapObj.map.getEventCoordinate(e);
            const position = { left: e.clientX, top: e.clientY };
            const pixel = this.mapObj.map.getPixelFromCoordinate(coordinate);
            let feature = null;

            /**
             * will be reassigning the feature variable only if:
             * either no tool is active or select tool is active
             */
            // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
            if (!activeTool || activeTool?.toolId === TOOLS_ID.SELECT_TOOL) {
                feature = this.mapObj.map.forEachFeatureAtPixel(pixel, (feature: $TSFixMe) => feature, {
                    checkWrapped: false
                });
            }

            /**
             * if user has opened the context-menu while select-tool is open
             * and not opened on any feature
             * then we'll dis-select the already selected features, if any
             */

            // @ts-expect-error TS(2339): Property 'toolId' does not exist on type 'never'.
            if (!feature && activeTool?.toolId === TOOLS_ID.SELECT_TOOL) {
                const toolIns = toolController.getToolInstance(TOOLS_ID.SELECT_TOOL);
                if (toolIns?.select) {
                    toolIns.select.getFeatures().clear();

                    toolIns.notifyObservers(TOOL_EVENT.SELECT_FEATURES, []);
                }
            }

            useContextMenu
                ?.getState()
                ?.dispatch({ type: 'SET_CONTEXTMENU', payload: { feature, position, visible: true } });
        });

        const reloadOutputsStyleOnZoomInOut = () => {
            try {
                const outputData = getOutputData();

                // change outputs
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                outputData?.outputs?.forEach((outputLayer: $TSFixMe) => {
                    const id = outputLayer?.id;
                    const layer = this.mapObj.getLayerById(id);
                    if (layer) {
                        this.updateLayerStyle(id, layer, outputLayer.style.width);
                    }
                });

                // change arrow layers
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                const { id: arrowLayerId, style: arrowLayerStyle } = outputData?.arrows || {};
                if (arrowLayerId) {
                    const layer = this.mapObj.getLayerById(MAP_LAYERS.ARROW);
                    this.updateLayerStyle(MAP_LAYERS.ARROW, layer, arrowLayerStyle.width);
                }

                // change Parcel layer in case of aerial
                if (!this.mapObj.isBlueprintMap) {
                    const parcelLayer = this.mapObj?.getParcelLayer();
                    if (parcelLayer) {
                        const { id, style } = parcelLayer.get('layerData') || {};
                        this.updateLayerStyle(id, parcelLayer, style.width);
                    }
                }
            } catch (err) {
                captureException(err);
            }
        };

        this.debouncedFn = debounce(reloadOutputsStyleOnZoomInOut, 20);
        // this is used to change geometry styles if user zooms in or out
        // In case of BPT we're changing styles after setting View of map ist
        // if (!this.mapObj.isBlueprintMap) {
        //     this.mapObj.map.getView().on('change:resolution', this.debouncedFn);
        // }

        if (!this.mapObj.isBlueprintMap) {
            this.mapObj.map.getView().on('change:resolution', debounce(this.updateOverlays, 100));
        }
    }

    setFeatureAttributeLabels(attributeMapper: $TSFixMe) {
        this.attributeMapper = attributeMapper;
    }

    setFeatureMeasurementLabels(measurementMapper: $TSFixMe) {
        this.measurementMapper = measurementMapper;
    }

    updateGeomTypeStyle = (geom_type: $TSFixMe, width: $TSFixMe) => {
        const outputData = getOutputData();

        // change outputs
        // @ts-expect-error TS(2339)
        outputData?.outputs?.forEach((outputLayer: $TSFixMe) => {
            const id = outputLayer?.id;
            const layer = this.mapObj.getLayerById(id);
            const feature_geom_type = outputLayer?.feature?.geometry_type;
            if (layer && feature_geom_type === geom_type) {
                this.updateLayerStyle(id, layer, width);
            }
        });
    };

    updateTooltipStatus = (showToolTip = true) => {
        this.showTooltip = showToolTip;
    };

    updateOverlays = () => {
        const overlays = this.mapObj.map.getOverlays().getArray();
        overlays.forEach((overlay: any) => {
            const labelBoxData = overlay.get('labelBoxData');

            if (labelBoxData) {
                const originalWidth = labelBoxData.width;
                const originalHeight = labelBoxData.height;

                const resolution = this.mapObj.map.getView().getResolution();
                const scaleFactor = 1 / resolution;

                const adjustedWidth = originalWidth * scaleFactor;
                const adjustedHeight = originalHeight * scaleFactor;

                const divElement = overlay.getElement();
                divElement.style.width = `${adjustedWidth}px`;
                divElement.style.height = `${adjustedHeight}px`;
            }
        });
    };

    removeOverlays() {
        const overlays = this.mapObj.map.getOverlays().getArray();
        overlays.forEach((overlay: $TSFixMe) => {
            const labelBoxData = overlay.get('labelBoxData');
            if (labelBoxData) {
                this.mapObj.map.removeOverlay(overlay);
            }
        });
    }

    dispatch(action: $TSFixMe) {
        useRequest.getState()?.dispatch(action);
    }

    updateLayerStyle(id: $TSFixMe, layer: $TSFixMe, widthVal: $TSFixMe) {
        const style = layer.getStyle();
        const resolution = this.mapObj.map.getView().getResolution();
        const isZoneLayer = layer.get('name') === MAP_LAYERS.ZONE;
        if (isZoneLayer) {
            const stroke = style?.getStroke();
            stroke && stroke.setWidth(widthVal);
            layer.setStyle(style);
            return;
        }

        const geomType = layer.get('layerData')?.feature?.geometry_type;
        const width = getNewLayerWidth({
            width: widthVal,
            geomType,
            isBlueprintMap: this.mapObj.isBlueprintMap,
            resolution
        });
        const hasPointShape = geomType === GEOMETRY_TYPE_ENUM.POINT && style?.getImage() instanceof Icon;
        if (id === MAP_LAYERS.ARROW) {
            const arrowTool = toolController.getToolInstance(TOOLS_ID.ADD_ARROW);
            arrowTool.updateWidth(width);
        } else if (
            geomType !== GEOMETRY_TYPE_ENUM.NUMERICAL &&
            !hasPointShape &&
            geomType !== GEOMETRY_TYPE_ENUM.TYPICAL_UNIT
        ) {
            const strokeStyle = new Stroke({
                color: style?.getStroke?.()?.getColor?.() || '#000',
                width
            });

            const fillStyle = new Fill({ color: style?.getFill?.()?.getColor?.() || '#000' });
            const imageStyle = new Circle({
                fill: fillStyle,
                stroke: strokeStyle,
                radius: width
            });
            layer.setStyle(
                new Style({
                    stroke: strokeStyle,
                    fill: fillStyle,
                    image: imageStyle
                })
            );
            return;
        }
        if (hasPointShape || geomType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT) {
            const image = style?.getImage();
            image && image.setScale(width / 12);
        }

        layer.setStyle(style);
    }

    addParcelLayer(data: $TSFixMe, zoomToParcel = false, fillParcel = false) {
        if (data.name === MAP_LAYERS.PARCEL && !data.aoi_geojson) {
            return this.addEmptyParcel(data, fillParcel);
        }
        if (data.name === MAP_LAYERS.PARCEL) {
            // Removing initial parcel layer when extended parcel comes
            const initialParcel = this.mapObj.getParcelLayer(true);
            if (initialParcel) {
                this.mapObj.removeLayer(initialParcel);
            }
        }
        const layer = this.loadVector(data);
        if (layer) {
            // @ts-expect-error TS(2339): Property 'is_manual_report' does not exist on type... Remove this comment to see the full error message
            const isManualReport = useRequest.getState()?.requestData?.is_manual_report;
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            const extent = layer.getSource().getExtent();
            if (zoomToParcel) this.mapObj.zoomToExtent(extent);
            // refresh extent when parcel is changed
            if (!isManualReport) tileImagery.updateExtent({ extent, isManualReport });
        }
        return null;
    }

    changeBlueprintPage({ onImageLoad, isOlderSheet, ...page }: $TSFixMe) {
        if (!page) return;
        if (this.pageTagElement) {
            this.removeInactiveTagOverlay();
        }
        this.mapObj.removeAllLayers();
        this.mapObj.addBlueprintBaseLayer(page, onImageLoad);
        // we'll be changing the width-style of layers on zoom in/out
        // this.mapObj.map.getView().on('change:resolution', this.debouncedFn);

        this.mapObj.map.getView().on('change:resolution', debounce(this.updateOverlays, 100));
        const baseLayerProps = this.mapObj?.baseLayer?.getProperties() || {};
        const polygon = fromExtent(baseLayerProps?.bp_page_extent);
        if (polygon) {
            const feature = new Feature(polygon);
            this.bpLotExtent = feature;
        }

        if (isOlderSheet) {
            if (this.pageTagElement) {
                this.pageTagElement.style.display = 'block';
            }
        }
        const isTypical = this.typicalSheet && page?.isTypical;
        this.typicalSheet.style.display = isTypical ? 'block' : 'none';
    }

    removeInactiveTagOverlay = () => {
        if (this.pageTagElement) {
            this.pageTagElement.style.display = 'none';
        }
    };

    // updateBaseLayerProperty(propertyName, newValue) {
    //     this.mapObj.baseLayer?.set(propertyName, newValue);
    // }

    addEmptyParcel(data: $TSFixMe, fillParcel = false) {
        if (!data.style.color) data.style = PARCEL_DEFAULT_STYLE;

        // Removing initial parcel layer when extended parcel comes
        const initialParcel = this.mapObj.getParcelLayer(true);
        if (initialParcel) {
            this.mapObj.removeLayer(initialParcel);
        }
        let { color } = data.style;
        color = asArray(color);
        color[3] = data.style.opacity;

        const fill = fillParcel ? new Fill({ color }) : null;

        const strokeStyle = new Stroke({
            color: data.style.color,
            width: data.style.width
        });
        this.mapObj.addLayer(
            new VectorLayer({
                // @ts-expect-error TS(2345): Argument of type '{ id: any; source: VectorSource<... Remove this comment to see the full error message
                id: data.id,
                source: new VectorSource({ wrapX: false }),
                layerData: data,
                name: data.name || MAP_LAYERS.PARCEL,
                zIndex: LAYER_INDEX.PARCEL,
                style: new Style({
                    stroke: strokeStyle,
                    // @ts-expect-error TS(2322): Type 'Fill | null' is not assignable to type 'Fill... Remove this comment to see the full error message
                    fill,
                    image: new Circle({
                        stroke: strokeStyle,
                        radius: data.style.width
                    })
                })
            })
        );
    }

    updateInitialParcel(data: $TSFixMe) {
        const initialParcel = this.mapObj.getParcelLayer(true);
        if (initialParcel) {
            this.mapObj.removeLayer(initialParcel);
        }
        const layer = this.loadVector(data);
        if (layer) {
            // @ts-expect-error TS(2531): Object is possibly 'null'.
            const extent = layer.getSource().getExtent();
            this.mapObj.zoomToExtent(extent);

            tileImagery.on({});
        }
    }

    loadImage(image: $TSFixMe) {
        if (this.hasLayer(MAP_LAYERS.IMAGERY)) return;
        const imgExtent = transformExtent([image.left, image.bottom, image.right, image.top], 'EPSG:4326', 'EPSG:3857');

        const imgSource = new Static({
            url: image.image_url,
            imageExtent: imgExtent,
            crossOrigin: 'anonymous'
        });

        const imgLayer = new ImageLayer({
            // @ts-expect-error TS(2345): Argument of type '{ id: string; source: Static; zI... Remove this comment to see the full error message
            id: MAP_LAYERS.IMAGERY,
            source: imgSource,
            zIndex: LAYER_INDEX.IMAGERY
        });

        imgSource.on('imageloaderror', err => {
            // eslint-disable-next-line no-underscore-dangle
            setExtra('URL', err.target.url_);
            // @ts-expect-error TS(2554): Expected 0-1 arguments, but got 2.
            captureException(new Error('Image failed to load', err));
        });

        this.mapObj.addLayer(imgLayer);
    }

    loadEmptyImage() {
        if (this.hasLayer(MAP_LAYERS.IMAGERY)) return;
        const imgLayer = new ImageLayer({
            // @ts-expect-error TS(2345): Argument of type '{ id: string; zIndex: number; }'... Remove this comment to see the full error message
            id: MAP_LAYERS.IMAGERY,
            zIndex: LAYER_INDEX.IMAGERY
        });
        this.mapObj.addLayer(imgLayer);
    }

    getParcelBbox() {
        const parcelNew = this.mapObj.getParcelLayer();
        if (parcelNew) {
            const extent = parcelNew.getSource().getExtent();
            const extentLatLon = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
            return {
                left: extentLatLon[0],
                bottom: extentLatLon[1],
                right: extentLatLon[2],
                top: extentLatLon[3]
            };
        }
        return null;
    }

    changeBaseImage(_image = {}) {
        const image = { ..._image };
        const layer = this.mapObj.getLayerById(MAP_LAYERS.IMAGERY);
        if (layer && Object.keys(image).length) {
            // @ts-expect-error TS(2339): Property 'left' does not exist on type '{}'.
            const bounds = [image.left, image.bottom, image.right, image.top];
            // @ts-expect-error TS(2339): Property 'orientation' does not exist on type '{}'... Remove this comment to see the full error message
            if (image.orientation === SIDE_VIEWS_MAP.WEST || image.orientation === SIDE_VIEWS_MAP.EAST) {
                if (bounds.length) {
                    // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
                    const rotatedBounds = bbox(transformRotate(bboxPolygon(bounds), 270));
                    const [left, bottom, right, top] = rotatedBounds;
                    Object.assign(image, { left, bottom, right, top });
                }
            }

            // @ts-expect-error TS(2339): Property 'left' does not exist on type '{}'.
            const newBounds = [image.left, image.bottom, image.right, image.top];
            const imgExtent = transformExtent([...newBounds], 'EPSG:4326', 'EPSG:3857');

            // @ts-expect-error TS(2339): Property 'orientation' does not exist on type '{}'... Remove this comment to see the full error message
            const rotateAngle = OBLIQUE_ROTATION[image.orientation] || 0;
            const rotation = rotateAngle * (Math.PI / 180);

            const imgElem = new Image();
            imgElem.onload = () => {
                const imageCenter = [(imgExtent[0] + imgExtent[2]) / 2, (imgExtent[1] + imgExtent[3]) / 2];
                const scaleX = (imgExtent[2] - imgExtent[0]) / imgElem.width;
                const scaleY = (imgExtent[3] - imgExtent[1]) / imgElem.height;

                const src = new GeoImageSource({
                    // @ts-expect-error TS(2339): Property 'url' does not exist on type '{}'.
                    url: image.url,
                    imageCenter,
                    imageScale: [scaleX, scaleY],
                    imageExtent: imgExtent,
                    projection: 'EPSG:3857',
                    imageRotate: rotation
                });
                // Change tile url while switching view to update image
                // @ts-expect-error TS(2339): Property 'url' does not exist on type '{}'.
                tileImagery.setTileUrl(image.url);
                layer.setSource(src);

                // @ts-expect-error TS(2339): Property 'orientation' does not exist on type '{}'... Remove this comment to see the full error message
                const mapRotation = MAP_ROTATION[image.orientation] || 0;
                const mapRotationAngle = mapRotation * (Math.PI / 180);
                this.mapObj.map.getView().setRotation(mapRotationAngle);

                this.mapObj.zoomToExtent(imgExtent);
            };
            // @ts-expect-error TS(2339): Property 'url' does not exist on type '{}'.
            imgElem.src = image.url;
        }
    }

    showBaseLayer = (show: $TSFixMe) => {
        const layer = this.mapObj.getLayerById(MAP_LAYERS.IMAGERY);
        if (layer) layer.setVisible(show);
    };

    loadOutputs(outputs: $TSFixMe) {
        if (!outputs) return;

        // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
        const requestId = getRequestData()?.id;

        const featPayload = [];
        for (let i = 0; i < outputs.length; i++) {
            const output = outputs[i];
            // removing default_tags from features which are deleted from tag library
            const default_tags = output?.feature?.default_tags || {};
            let hasDeleted = false;
            Object.keys(default_tags).forEach(tagTypeId => {
                const { tagId, subtagId } = default_tags[tagTypeId];

                // if tag/subtag of particular tagtype is deleted from tagsLib
                // we're removing entire tagtype from feature

                if (!this.isTagPresentInTagsLib(tagTypeId, tagId, subtagId)) {
                    hasDeleted = true;
                    delete default_tags[tagTypeId];
                }
            });
            hasDeleted && featPayload.push({ feature_id: output?.id, default_tags });

            this.loadVector(outputs[i]);
        }
        if (featPayload?.length) {
            const url = interpolate(ADD_BLUEPRINT_FEATURE_TAGS, [requestId]);

            postAPI(url, { data: featPayload })
                .then(() => {})
                .catch(err => {
                    captureException(err);
                });
        }
    }

    loadZones(zonal_boundaries: any, resetLayers: boolean = false) {
        if (resetLayers) this.removeAllZoneLayers();

        for (let i = 0; i < zonal_boundaries?.length; i++) {
            this.loadZoneVector(zonal_boundaries[i]);
        }
    }

    unloadOutput() {
        this.mapObj.removeAllLayers();
    }

    isTagPresentInTagsLib(tagTypeId: $TSFixMe, tagId: $TSFixMe, subtagId: $TSFixMe) {
        const massagedTags: $TSFixMe = useTags.getState()?.massagedTags;
        const tagFetchingStatus = useTags.getState()?.fetchingStatus;
        // @ts-expect-error
        if (tagFetchingStatus === NETWORK_CALL_STATUS.SUCCESS) {
            if (
                Object.prototype.hasOwnProperty.call(massagedTags, tagTypeId) &&
                Object.prototype.hasOwnProperty.call(massagedTags[tagTypeId], tagId)
            ) {
                if (subtagId) {
                    return massagedTags[tagTypeId][tagId].includes(subtagId);
                }

                return true;
            }
            return false;
        }
        return true;
    }

    loadVector(data: $TSFixMe) {
        const prevLayer = this.mapObj.getLayerById(data.id);
        if (prevLayer) {
            this.mapObj.removeLayer(prevLayer);
        }

        const isOldParcel = data.name === MAP_LAYERS.OLD_PARCEL;
        const isParcel = data.name === MAP_LAYERS.PARCEL || isOldParcel;
        // We don't want to load inactive layers
        if (!data?.is_active && !isParcel) return null;

        // @ts-expect-error TS(2339): Property 'status' does not exist on type '{}'.
        const isDraftRequest = getRequestData().status === REQUEST_STATUS_ENUM.DRAFT;

        // removing tags from geometries which are deleted from tags library
        data.output_geojson?.features?.forEach((feat: $TSFixMe) => {
            const tags_info = feat.properties?.tags_info || {};
            if (isPlainObject(tags_info)) {
                Object.keys(tags_info)?.forEach(tagTypeId => {
                    const { tagId, subtagId } = tags_info?.[tagTypeId] || {};

                    // if tag/subtag of particular tagtype is deleted from tagsLib
                    // we're removing entire tagtype from geometry

                    if (!this.isTagPresentInTagsLib(tagTypeId, tagId, subtagId)) {
                        delete tags_info?.[tagTypeId];
                    }
                });
            }
        });

        const geomteries = data?.output_geojson?.features || [];

        //    filtering the geomteries on basis of tagquery
        // filtering the geomteries on basis of tagquery
        const featureTags = data?.feature?.default_tags || {};

        const { visibleGeoms } = getVisibleGeoms({ layerId: data?.id, geoms: geomteries, featureTags });

        const featureGeojson = isParcel
            ? transformMutiPolyToFeatures(data.aoi_geojson)
            : { ...data.output_geojson, features: visibleGeoms };

        const src = new VectorSource({
            wrapX: false,
            features: this.mapObj.isBlueprintMap
                ? new GeoJSON().readFeatures(featureGeojson)
                : new GeoJSON().readFeatures(featureGeojson, {
                      dataProjection: 'EPSG:4326',
                      featureProjection: 'EPSG:3857'
                  })
        });

        // Populate layer id in each feature (took only 16ms for 4K features)
        src.forEachFeature(f => {
            f.setProperties({ layerId: data.id }, true);
        });

        const { color, opacity, width, pattern = null } = data?.style || {};
        const fillColor = cloneDeep(asArray(color));
        fillColor.splice(3, 1, opacity);

        const geomType = data?.feature?.geometry_type;
        let fillStyle;
        if (
            geomType !== GEOMETRY_TYPE_ENUM.POINT &&
            geomType !== GEOMETRY_TYPE_ENUM.TYPICAL_UNIT &&
            pattern &&
            pattern !== FILL_PATTERNS_ENUM.NO_PATTERN
        ) {
            const fillPattern = createPattern(color, opacity, pattern);
            fillStyle = new Fill({ color: fillPattern });
        } else {
            // No fill color for parcel layer when request is in completed status
            fillStyle = isParcel && !isDraftRequest ? null : new Fill({ color: fillColor });
        }

        // while loading layers we're changing the width according to map resolution
        const resolution = this.mapObj.map.getView().getResolution();
        const newWidth = getNewLayerWidth({
            width,
            geomType,
            isBlueprintMap: this.mapObj.isBlueprintMap,
            resolution
        });

        const strokeStyle = new Stroke({
            color,
            width: newWidth
        });

        const imageStyle =
            geomType === GEOMETRY_TYPE_ENUM.NUMERICAL
                ? new Icon({
                      color,
                      src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/sharp.svg',
                      crossOrigin: 'anonymous'
                  })
                : geomType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT
                  ? new Icon({
                        src: TYPICAL_ICON.image,
                        color,
                        scale: newWidth / 12,
                        crossOrigin: 'anonymous'
                    })
                  : geomType === GEOMETRY_TYPE_ENUM.POINT && pattern && pattern !== POINT_SHAPES_ENUM.NO_PATTERN
                    ? new Icon({
                          src: POINT_SHAPES[pattern].image,
                          color,
                          scale: newWidth / 12,
                          crossOrigin: 'anonymous'
                      })
                    : new Circle({
                          // @ts-expect-error TS(2322): Type 'Fill | null' is not assignable to type 'Fill... Remove this comment to see the full error message
                          fill: fillStyle,
                          stroke: strokeStyle,
                          radius: newWidth
                      });

        const LayerClass = isParcel ? VectorLayer : VectorImageLayer;

        const layer = new LayerClass({
            // @ts-expect-error TS(2345): Argument of type '{ id: any; source: VectorSource<... Remove this comment to see the full error message
            id: data.id,
            source: src,
            layerData: data,
            name: data.name || MAP_LAYERS.OUTPUT,
            zIndex: isOldParcel ? 1 : LAYER_INDEX[data.feature?.geometry_type] || LAYER_INDEX.POLYGON,
            style: new Style({
                stroke: strokeStyle,
                // @ts-expect-error TS(2322): Type 'Fill | null' is not assignable to type 'Fill... Remove this comment to see the full error message
                fill: fillStyle,
                image: imageStyle
            })
        });

        if (isParcel && !editParcel.parcelVisibility) {
            layer.setVisible(false);
        } else if (!data.style.is_visible) {
            layer.setVisible(false);
        }

        this.mapObj.addLayer(layer);

        if (isParcel) {
            return layer;
        }
        return null;
    }

    loadZoneVector(data: $TSFixMe) {
        const prevLayer = this.mapObj.getLayerById(data.id);
        if (prevLayer) {
            this.mapObj.removeLayer(prevLayer);
        }

        const zoneGeojson = data?.zonal_boundary;

        const src = new VectorSource({
            wrapX: false,
            features: new GeoJSON().readFeatures(zoneGeojson, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            })
        });
        // Populate layer id in each zone (took only 16ms for 4K features)
        src.forEachFeature(feature => {
            feature.setProperties({ layerId: data.id }, true);
        });

        const layer = new VectorImageLayer({
            // @ts-expect-error TS(2345): Argument of type '{ id: any; source: VectorSource<... Remove this comment to see the full error message
            id: data.id,
            source: src,
            layerData: data,
            name: MAP_LAYERS.ZONE,
            zoneName: data?.name,
            zIndex: LAYER_INDEX.ZONE,
            style: ZONAL_STYLE(
                data?.name,
                data?.style?.color,
                data?.style?.opacity,
                data?.style?.hide_label,
                data?.style?.width
            )
        });

        this.mapObj.addLayer(layer);
    }

    setVisibility(id: $TSFixMe, value: $TSFixMe) {
        const layer = this.mapObj.getLayerById(id);
        if (layer) {
            layer.setVisible(value);
        }
    }

    setVisibilityByName(name: $TSFixMe, value: $TSFixMe) {
        const layers = this.mapObj.map.getLayers();
        layers.forEach((layer: $TSFixMe) => {
            // Get only changed layers if overall then all layers
            if (layer.get('name') === name) {
                layer.setVisible(value);
            }
        });
    }

    setWidth(id: $TSFixMe, widthVal: $TSFixMe, set_default: $TSFixMe, save = true) {
        const layer = this.mapObj.getLayerById(id);
        if (layer) {
            const props = layer.getProperties();
            const patternEnum = props.layerData?.style?.pattern;
            this.updateLayerStyle(id, layer, widthVal);
            if (save) this.saveStyle(id, set_default, widthVal, patternEnum);
        }
    }

    setColor(id: $TSFixMe, color: $TSFixMe, set_default: $TSFixMe, save = true, hasPattern = null, patternEnum = null) {
        const layer = this.mapObj.getLayerById(id);
        if (layer) {
            let style = layer.getStyle();
            const props = layer.getProperties();
            const geometryType = props.layerData?.feature?.geometry_type;

            if (id === MAP_LAYERS.ARROW) {
                const arrowTool = toolController.getToolInstance(TOOLS_ID.ADD_ARROW);
                arrowTool.updateColor(color);
            } else if (geometryType === GEOMETRY_TYPE_ENUM.NUMERICAL) {
                style = new Style({
                    image: new Icon({
                        color,
                        src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/sharp.svg',
                        crossOrigin: 'anonymous'
                    })
                });
            } else {
                const stroke = style?.getStroke();
                let fill = style?.getFill();

                stroke.setColor(color);

                // for point that are shapes
                if (geometryType === GEOMETRY_TYPE_ENUM.POINT || geometryType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT) {
                    // set the icon
                    if (hasPattern || geometryType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT) {
                        const scale = style?.getImage()?.getScale();

                        // @ts-expect-error TS(2538): Type 'null' cannot be used as an index type.
                        const src = hasPattern ? POINT_SHAPES[patternEnum]?.image : TYPICAL_ICON.image;

                        style.setImage(
                            new Icon({
                                src,
                                color,
                                scale,
                                crossOrigin: 'anonymous'
                            })
                        );
                    } else {
                        const { width } = props?.layerData?.style || {};
                        const imageStyle = new Circle({
                            fill,
                            stroke,
                            radius: width
                        });
                        style.setImage(imageStyle);
                    }
                } else if (fill) {
                    let _color_array;
                    if (hasPattern) {
                        const fillPattern = createPattern(color, props.layerData?.style?.opacity, patternEnum);
                        fill.setColor(fillPattern);
                    } else {
                        const _color = fill.getColor();
                        let opac;

                        if (_color instanceof CanvasPattern) {
                            fill = new Fill({});
                            opac = props.layerData?.style?.opacity;
                            _color_array = asArray(props.layerData?.style?.color);
                        } else {
                            [, , , opac] = _color;
                            _color_array = asArray(color);
                        }
                        if (layer.get('name') === MAP_LAYERS.PARCEL) _color_array[3] = 0.3;
                        else _color_array[3] = opac;
                        fill.setColor(_color_array);

                        if (_color instanceof CanvasPattern) {
                            style.fill_ = fill;
                        }
                    }
                }

                const image = style?.getImage();

                if (image && !(image instanceof Icon)) {
                    image.getStroke().setColor(color);
                    image.setRadius(image.getRadius());
                }
            }

            layer.setStyle(style);
            if (save) this.saveStyle(id, set_default, null, patternEnum);
        }
    }

    setOpacity(id: $TSFixMe, value: $TSFixMe, set_default: $TSFixMe, save = true, hasPattern = false) {
        const layer = this.mapObj.getLayerById(id);
        if (layer) {
            const style = layer.getStyle();
            // Use Fill color in case of zone layer else use Stroke
            const isZoneLayer = layer?.get('name') === MAP_LAYERS.ZONE;
            const hexColor = isZoneLayer ? style.getFill().getColor() : style.getStroke().getColor();

            const fill = style.getFill();

            const layerData = layer.get('layerData');
            const patternEnum = layerData?.style?.pattern;
            if (hasPattern) {
                const fillPattern = createPattern(hexColor, value, patternEnum);
                fill.setColor(fillPattern);

                layer.set('layerData', { ...layerData, style: { ...layerData.style, opacity: value } });
            } else {
                let color = asArray(hexColor);
                color = color.slice();
                color[3] = value;
                fill.setColor(color);
            }
            layer.setStyle(style);
            if (save) this.saveStyle(id, set_default, null, patternEnum);
        }
    }

    zoomToLatLon(lat: $TSFixMe, lon: $TSFixMe) {
        this.mapObj.zoomToExtent([...fromLonLat([lon, lat]), ...fromLonLat([lon, lat])]);
    }

    hasLayer(id: $TSFixMe) {
        return Boolean(this.mapObj.getLayerById(id));
    }

    hideZoneLabel = (id: string, val: boolean) => {
        const layer = this.mapObj.getLayerById(id);
        if (layer) {
            const style = layer.getStyle();

            if (val) {
                style.text_ = null;
            } else {
                const { name } = layer.getProperties().layerData;
                style.text_ = new Text({
                    text: name,
                    fill: new Fill({
                        color: '#ffffff'
                    }),
                    backgroundFill: new Fill({
                        color: 'rgba(0, 0, 0, 1)'
                    }),
                    placement: 'point',
                    textBaseline: 'top',
                    font: '12px sans-serif',
                    overflow: true
                });
            }

            layer.setStyle(style);
        }
    };

    // eslint-disable-next-line no-unused-vars
    saveStyle(id: $TSFixMe, set_default: $TSFixMe, widthVal = null, patternEnum = null) {
        // Prevent API call in shared view
        // @ts-expect-error TS(2339): Property 'isSharedView' does not exist on type 'Wi... Remove this comment to see the full error message
        if (!window.isSharedView) {
            const layer = this.mapObj.getLayerById(id);
            if (layer) {
                const isOldParcel = layer?.get('name') === MAP_LAYERS.OLD_PARCEL;
                if (isOldParcel) return;

                clearTimeout(this.timeout);

                const isArrowLayer = id === MAP_LAYERS.ARROW;
                const isZoneLayer = layer.get('name') === MAP_LAYERS.ZONE;

                const requestData: Partial<RequestDataInterface> = getRequestData();
                const outputData: Partial<OutputDataInterface> = getOutputData();

                const requestId = requestData?.id;
                const isDraftRequest = requestData?.status === REQUEST_STATUS_ENUM.DRAFT;
                const styleObj = isArrowLayer
                    ? toolController.getToolInstance(TOOLS_ID.ADD_ARROW)?.getStyle() || {}
                    : this.getLayerStyle(layer, isDraftRequest, patternEnum);
                const isParcel = layer?.get('name') === MAP_LAYERS.PARCEL;

                let params = {};
                let prefix = '';

                if (this.mapObj.isBlueprintMap) {
                    const worksheet_id = this.mapObj.baseLayer?.getProperties()?.bp_page_id;
                    params = { worksheet_id };
                    prefix = BP_PREFIX;
                }

                // parcel style: for draft case update the request object else update the output object
                const url = isArrowLayer
                    ? interpolate(UPDATE_ARROWS, [requestId, layer.get('arrowRequestId')])
                    : isParcel
                      ? interpolate(isDraftRequest ? REQUEST : AOI_STYLE, [requestId])
                      : isZoneLayer
                        ? interpolate(ZONE_STYLE, [requestId])
                        : interpolate(STYLE, [requestId, id]);

                const apiCall = isDraftRequest || isArrowLayer ? patchAPI : postAPI;

                if (isZoneLayer) {
                    delete styleObj.style.layer_order;
                } else {
                    styleObj.set_default = Boolean(set_default);
                }

                const data = isDraftRequest ? { style: styleObj } : isZoneLayer ? { id, ...styleObj } : styleObj;

                this.timeout = setTimeout(() => {
                    apiCall(url, { data, params, prefix }).then(() => {
                        if (isZoneLayer) {
                            if (outputData.zonal_boundaries?.length) {
                                outputData.zonal_boundaries.forEach((zone: any) => {
                                    if (zone.id === id) {
                                        zone.style = Object.assign(zone.style, styleObj.style);
                                    }
                                });
                            }
                        } else {
                            if (outputData.outputs?.length) {
                                outputData.outputs.forEach((output: $TSFixMe) => {
                                    if (output.id === id) {
                                        // mutating intentionally
                                        output.style = Object.assign(output.style, styleObj.style);
                                    }
                                });
                            }
                        }

                        const layerData = layer.get('layerData');
                        layer.set('layerData', { ...layerData, style: { ...data.style } });
                    });
                }, 500);
            }
        }
    }

    getLayerStyle(layer: $TSFixMe, isDraftRequest: $TSFixMe, patternEnum = null) {
        const style = layer.getStyle();
        const props = layer.getProperties();
        const geometryType = props.layerData?.feature?.geometry_type;
        const isZoneLayer = layer?.get('name') === MAP_LAYERS.ZONE;

        if (geometryType === GEOMETRY_TYPE_ENUM.NUMERICAL) {
            // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
            const color = rgbToHex(...style.getImage().getColor());
            return { style: { color, is_visible: true, opacity: 1 } };
        }

        const data: any = {};
        const isParcel = layer.get('name') === MAP_LAYERS.PARCEL || layer.get('name') === MAP_LAYERS.OLD_PARCEL;
        const stroke = style.getStroke();
        const fill = style.getFill();
        const isPointShape =
            geometryType === GEOMETRY_TYPE_ENUM.POINT &&
            style.getImage() instanceof Icon &&
            patternEnum &&
            patternEnum !== POINT_SHAPES_ENUM.NO_PATTERN;
        if (fill) {
            const fillType = fill.getColor();
            if (isPointShape) {
                data.pattern = patternEnum;
            }
            if (fillType instanceof CanvasPattern) {
                data.pattern = patternEnum;
                data.opacity = props.layerData?.style?.opacity;
            } else {
                let color = asArray(fillType);
                color = color.slice();
                const [, , , opacity] = color;
                data.opacity = opacity;
            }
        }
        data.is_visible = layer.getVisible();
        data.color = stroke.getColor();
        data.width =
            isPointShape || geometryType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT
                ? style.getImage().getScale() * 12
                : stroke.getWidth();
        data.layer_order = 1;
        // do not persist opacity of parcel layer
        if (!data.opacity || isParcel) {
            if (isParcel && isDraftRequest) {
                data.opacity = 0.3;
            } else {
                data.opacity = 0;
            }
        }
        if (isZoneLayer) data.hide_label = props.layerData?.style?.hide_label;
        return { style: data };
    }

    getLayersGeojson = ({
        overall = false,
        layerName = MAP_LAYERS.OUTPUT,
        includeEmpty = true,
        idKey = 'id',
        geoKey = 'output_geojson'
    } = {}) => {
        const layers = this.mapObj.map.getLayers();
        const outputs: $TSFixMe = [];

        layers.forEach((layer: $TSFixMe) => {
            // Get only changed layers if overall then all layers
            if (
                layer.get('name') === layerName &&
                (overall || layerTracker.hasValueAtKey(layer.get('name'), layer.get('id')))
            ) {
                const layerGeojson = this.getGeojsonByLayer(layer, { includeEmpty });
                if (!layerGeojson) return;

                const layerData = layer.getProperties()?.layerData || {};
                const {
                    style: layerStyle,
                    feature,
                    measurements,
                    is_active,
                    assemblies,
                    attributes,
                    attribute_based_measurements,
                    group
                } = layerData;

                outputs.push({
                    [idKey]: layer.get('id'),
                    [geoKey]: layerGeojson,
                    style: layerStyle,
                    feature,
                    measurements,
                    assemblies,
                    attributes,
                    attribute_based_measurements,
                    is_active,
                    group
                });
            }
        });

        return outputs;
    };

    getZoneLayersGeojson = ({ zonesToBeDeleted }: any) => {
        const layers = this.mapObj.map.getLayers();
        const zones: any = [];

        layers.forEach((layer: any) => {
            if (
                layer.get('name') === MAP_LAYERS.ZONE &&
                layerTracker.hasValueAtKey(layer.get('name'), layer.get('id'))
            ) {
                const layerGeojson = this.getGeojsonByLayer(layer);
                if (!layerGeojson) return;

                const style = layer.getProperties()?.layerData?.style || {};
                // Delete the merged zones if performing merge else Update/Create the zones
                let actionForZone;
                if (zonesToBeDeleted && zonesToBeDeleted.includes(layer)) actionForZone = ZONE_ACTIONS.DELETE;
                else actionForZone = ZONE_ACTIONS.CREATE;

                zones.push({
                    id: layer.get('id'),
                    name: layer.get('zoneName'),
                    zonal_boundary: layerGeojson,
                    action: actionForZone,
                    style
                });
            }
        });

        return zones;
    };

    removeAllZoneLayers = () => {
        const layers = this.mapObj.map.getLayers();
        const layersToRemove: any = [];

        layers.forEach((layer: any) => {
            if (layer && layer.get('name') === MAP_LAYERS.ZONE) {
                layersToRemove.push(layer);
            }
        });

        layersToRemove.forEach((layer: any) => {
            this.mapObj.removeLayer(layer);
        });
    };

    /**
     * Get geojson from openlayers Layer
     * @param {Layer} layer
     * @returns Geojson
     */
    getGeojsonByLayer(layer: $TSFixMe, { includeEmpty = true, blackListProperties = [] } = {}) {
        const hiddenGeoms: $TSFixMe = useRequest?.getState()?.hiddenGeoms;
        if (layer) {
            const layerId = layer.get('id');
            const features = filterBlacklistPropertiesInFeatures(layer.getSource().getFeatures(), blackListProperties);
            if (!features?.length && !includeEmpty) return null;
            const geojson = new GeoJSON();
            const projectionTransformation = this.mapObj.isBlueprintMap
                ? { decimals: 10 }
                : {
                      dataProjection: 'EPSG:4326',
                      featureProjection: 'EPSG:3857',
                      decimals: 10
                  };
            const parcelGeojson: any = JSON.parse(geojson.writeFeatures(features, projectionTransformation) || null!);
            if (parcelGeojson) {
                if (hiddenGeoms[layerId]?.length) {
                    parcelGeojson.features.push(...hiddenGeoms[layerId]);
                }

                return parcelGeojson;
            }

            return null;
        }
        return null;
    }

    /**
     * Get geojson from openlayers feature
     * @param {Feature} feature
     * @returns Geojson
     */
    getGeojsonByFeature(feature: $TSFixMe, conversion = true) {
        const format = new GeoJSON();
        const projection = conversion
            ? {
                  dataProjection: 'EPSG:4326',
                  featureProjection: 'EPSG:3857',
                  decimals: 10
              }
            : undefined;
        const geojson = format.writeFeature(feature, projection);
        return geojson && JSON.parse(geojson);
    }

    restoreParcelLayer(data: $TSFixMe) {
        this.addParcelLayer(data);
    }

    restoreOutputLayers(data: $TSFixMe) {
        this.loadOutputs(data);
    }

    restoreZoneLayers(data: $TSFixMe) {
        this.loadZones(data);
    }

    highlight({ featId = null, layerId, blink = false, isVisible }: $TSFixMe) {
        const layer = this.mapObj.getLayerById(layerId);

        if (layer) {
            const features = layer.getSource().getFeatures();
            this.clearHighlightFeature();
            this.increaseZIndex(layerId);

            if (layer.get('name') === MAP_LAYERS.ZONE) {
                features.forEach((feat: $TSFixMe) => feat && this.highlightZoneInteraction?.getFeatures()?.push(feat));
            } else if (featId) {
                const feature = features?.find((feat: $TSFixMe) => feat.get('id') === featId);
                this.highlightInteraction?.getFeatures()?.push(feature);
            } else if (blink) {
                this.highlightInteraction?.getFeatures()?.extend(features);
                const payload = {
                    id: layerId,
                    geomType: layer?.get('layerData')?.feature?.geometry_type,
                    isVisible
                };
                this.dispatch({ type: 'SET_BLINKED_LAYER', payload });
                const blinkIntervalId = useRequest.getState()?.blinkedLyr?.intervalId;
                if (blinkIntervalId) {
                    clearInterval(blinkIntervalId);
                }
                this.blinkFeatures({ layer, features });
            }
        }
    }

    blinkFeatures = ({ layer, features }: $TSFixMe) => {
        const getStyle = (width: $TSFixMe, fillStyle: $TSFixMe) => {
            const strokeStyle = new Stroke({
                color: 'red',
                width
            });
            const imageStyle = new RegularShape({
                stroke: strokeStyle,
                fill: fillStyle,
                points: 4,
                radius: width * Math.sqrt(2),
                angle: Math.PI / 4
            });
            return new Style({
                fill: fillStyle,
                stroke: strokeStyle,
                image: imageStyle
            });
        };
        const geomType = layer?.get('layerData')?.feature.geometry_type;

        const pattern = layer?.get('layerData')?.style?.pattern || null;

        if (
            geomType === GEOMETRY_TYPE_ENUM.NUMERICAL ||
            (geomType === GEOMETRY_TYPE_ENUM.POINT && pattern && pattern !== POINT_SHAPES_ENUM.NO_PATTERN)
        ) {
            let scale = 1.3;
            const blinkIntervalId = setInterval(() => {
                scale = scale === 1 ? 1.3 : 1;
                features.forEach((feat: $TSFixMe) => {
                    feat.setStyle(
                        new Style({
                            image: new Icon({
                                src:
                                    geomType === GEOMETRY_TYPE_ENUM.NUMERICAL
                                        ? 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/sharp.svg'
                                        : POINT_SHAPES[pattern].image,
                                color: 'rgba(255,0,0,0.7)',
                                scale,
                                crossOrigin: 'anonymous'
                            })
                        })
                    );
                });
            }, 500);
            this.dispatch({ type: 'SET_BLINKED_LAYER', payload: { intervalId: blinkIntervalId } });

            setTimeout(() => {
                clearInterval(blinkIntervalId);
            }, 2000);
        } else {
            let orgWidth = 3;
            if (layer) {
                orgWidth = layer?.get('layerData')?.style?.width || 3;
            }
            let width = orgWidth + 3;
            let fillStyle: $TSFixMe = null;
            if (geomType !== GEOMETRY_TYPE_ENUM.POINT && pattern && pattern !== FILL_PATTERNS_ENUM.NO_PATTERN) {
                const fillPattern = createPattern('#FF0000', 0.3, pattern);
                fillStyle = new Fill({ color: fillPattern });
            } else {
                fillStyle = new Fill({ color: 'rgba(255,0,0,0.3)' });
            }

            const blinkIntervalId = setInterval(() => {
                width = width === orgWidth ? orgWidth + 3 : orgWidth;
                features.forEach((feat: $TSFixMe) => {
                    feat.setStyle(getStyle(width, fillStyle));
                });
            }, 500);
            this.dispatch({ type: 'SET_BLINKED_LAYER', payload: { intervalId: blinkIntervalId } });

            setTimeout(() => {
                clearInterval(blinkIntervalId);
            }, 2000);
        }
    };

    clearHighlightFeature() {
        this.highlightInteraction?.getFeatures()?.clear();
        this.highlightZoneInteraction?.getFeatures().clear();
        const blinkIntervalId = useRequest.getState()?.blinkedLyr?.intervalId;
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        clearInterval(blinkIntervalId);
        this.dispatch({
            type: 'SET_BLINKED_LAYER',
            payload: { id: null, geomType: null, isVisible: null, intervalId: null }
        });
    }

    increaseZIndex(id: $TSFixMe) {
        const layers = this.mapObj.map.getLayers();
        layers.forEach((layer: $TSFixMe) => {
            if (layer.get('name') === MAP_LAYERS.OUTPUT) {
                layer.setZIndex(10);
            }
        });

        const targetLayer = this.mapObj.getLayerById(id);
        // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
        if (targetLayer && !targetLayer.get('name') === MAP_LAYERS.ZONE) {
            targetLayer.setZIndex(12);
        }
    }

    toggleLayers = (val: $TSFixMe) => {
        try {
            if (this.toggleAllLayersState) this.toggleAllLayersState(val);

            const addLabel = toolController.getToolInstance(TOOLS_ID.LABEL);
            if (addLabel) this.setVisibility(MAP_LAYERS.LABELS, val);

            const arrowTool = toolController.getToolInstance(TOOLS_ID.ADD_ARROW);
            if (arrowTool) arrowTool.setArrowVisibility(val);

            const uploadIcon = toolController.getToolInstance(TOOLS_ID.UPLOAD_ICON);
            if (uploadIcon) uploadIcon.setIconVisibility(val);

            const notesTool = toolController.getToolInstance(TOOLS_ID.NOTES_TOOL);
            if (notesTool) notesTool.setNotesVisibility(val);
        } catch (error) {
            // @ts-expect-error TS(2345): Argument of type '"Unable to toggle layers"' is no... Remove this comment to see the full error message
            captureException(error, 'Unable to toggle layers');
        }
    };

    toggleAllFeaturesLayers(value: $TSFixMe) {
        const layers = this.mapObj.map?.getLayers();
        layers?.forEach((layer: $TSFixMe) => {
            if (layer.get('name') === MAP_LAYERS.OUTPUT) {
                layer.setVisible(value);
            }
        });
    }

    toggleAllZonesLayers(value: $TSFixMe) {
        const layers = this.mapObj.map?.getLayers();
        layers?.forEach((layer: $TSFixMe) => {
            if (layer.get('name') === MAP_LAYERS.ZONE) {
                layer.setVisible(value);
            }
        });
    }

    toggleLayerByGeometry(geom: $TSFixMe, value: $TSFixMe) {
        const layers = this.mapObj.map.getLayers();
        layers.forEach((layer: $TSFixMe) => {
            if (layer.get('name') === MAP_LAYERS.OUTPUT && layer?.get('layerData')?.feature?.geometry_type === geom) {
                layer.setVisible(value);
            }
        });
    }

    toggleAllGroupLayers(groupId: $TSFixMe, value: $TSFixMe) {
        const layers = this.mapObj.map.getLayers();
        if (groupId) {
            layers.forEach((layer: $TSFixMe) => {
                if (layer.get('name') === MAP_LAYERS.OUTPUT && layer?.get('layerData')?.group?.id === groupId) {
                    layer.setVisible(value);
                }
            });
        } else {
            layers.forEach((layer: $TSFixMe) => {
                if (layer.get('name') === MAP_LAYERS.OUTPUT && !layer?.get('layerData')?.group?.id) {
                    layer.setVisible(value);
                }
            });
        }
    }

    toggleAllGroupGeoLayers(groupId: $TSFixMe, geom: $TSFixMe, value: $TSFixMe) {
        const layers = this.mapObj.map.getLayers();
        if (groupId) {
            layers.forEach((layer: $TSFixMe) => {
                if (
                    layer.get('name') === MAP_LAYERS.OUTPUT &&
                    layer?.get('layerData')?.group?.id === groupId &&
                    layer?.get('layerData')?.feature?.geometry_type === geom
                ) {
                    layer.setVisible(value);
                }
            });
        } else {
            layers.forEach((layer: $TSFixMe) => {
                if (
                    layer.get('name') === MAP_LAYERS.OUTPUT &&
                    !layer?.get('layerData')?.group?.id &&
                    layer?.get('layerData')?.feature?.geometry_type === geom
                ) {
                    layer.setVisible(value);
                }
            });
        }
    }

    setHandler = (type: $TSFixMe, handler: $TSFixMe) => {
        switch (type) {
            case 'toggleAllLayersState':
                this.toggleAllLayersState = handler;

                break;
            default:
                break;
        }
    };

    updateMeasurementUnit = (value: $TSFixMe) => {
        this.isImperialSystem = value;
    };

    updateHideMeasurements = (value: $TSFixMe) => {
        this.hideMeasurements = value;
    };

    zoomOutToOverlays() {
        const overlays = this.mapObj.map.getOverlays().getArray();

        let finalExtent: $TSFixMe = null;
        if (overlays.length > 1) {
            overlays.forEach((overlay: $TSFixMe) => {
                if (!overlay.get('labelBoxData')) return;

                const extent = overlay.get('labelBoxData')?.extent;
                finalExtent = finalExtent ? extend(extent, finalExtent) : extent;
            });

            const polygon = new Polygon([
                [
                    [finalExtent[0], finalExtent[1]],
                    [finalExtent[0], finalExtent[3]],
                    [finalExtent[2], finalExtent[3]],
                    [finalExtent[2], finalExtent[1]],
                    [finalExtent[0], finalExtent[1]]
                ]
            ]);
            const feature = new Feature(polygon);
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            finalExtent = feature.getGeometry().getExtent();
        } else {
            const parcelExtent = editParcel.getParceExtent();
            finalExtent = parcelExtent;
        }
        this.mapObj.zoomToExtent(finalExtent);
    }

    // @ts-expect-error
    highlightFeatureName = id => {
        const layer = this.mapObj.getLayerById(id);
        if (layer) {
            const style = layer.getStyle();
            let highlightedLayers = useRequest.getState()?.highlightedLayers;

            // @ts-expect-error
            if (highlightedLayers.includes(id)) {
                highlightedLayers = highlightedLayers.filter(lyrId => lyrId !== id);
                style.text_ = null;
            } else {
                const { feature } = layer.getProperties().layerData;
                style.text_ = new Text({
                    text: feature.name,
                    fill: new Fill({
                        color: '#ffffff'
                    }),
                    backgroundFill: new Fill({
                        color: 'rgba(0, 0, 0, 1)'
                    }),
                    placement: 'point',
                    font: '12px sans-serif',
                    overflow: true
                });
                // @ts-expect-error
                highlightedLayers.push(id);
            }

            layer.setStyle(style);
            this.dispatch({ type: 'SET_HIGHLIGHTED_LAYERS', payload: highlightedLayers });
        }
    };
}

export default OutputMap;

const getLengthMeasurementUnit = (isImperialSystem: $TSFixMe) =>
    isImperialSystem ? IMPERIAL_ATTRIBUTE_UNIT_TYPES.FT : METRIC_ATTRIBUTE_UNIT_TYPES.M;
const getAreaMeasurementUnit = (isImperialSystem: $TSFixMe) =>
    isImperialSystem ? IMPERIAL_ATTRIBUTE_UNIT_TYPES.SQFT : METRIC_ATTRIBUTE_UNIT_TYPES.SQM;
const getVolumeMeasurementUnit = (isImperialSystem: $TSFixMe) =>
    isImperialSystem ? IMPERIAL_ATTRIBUTE_UNIT_TYPES.CUYD : METRIC_ATTRIBUTE_UNIT_TYPES.M3;
const getWeightMeasurementUnit = (isImperialSystem: $TSFixMe) =>
    isImperialSystem ? IMPERIAL_ATTRIBUTE_UNIT_TYPES.LB : METRIC_ATTRIBUTE_UNIT_TYPES.KG;
const getWeightTonMeasurementUnit = (isImperialSystem: $TSFixMe) =>
    isImperialSystem ? IMPERIAL_ATTRIBUTE_UNIT_TYPES.LB_TON : METRIC_ATTRIBUTE_UNIT_TYPES.KG_TON;

const getMaterialsUnit = (unit_name: $TSFixMe) => {
    switch (unit_name) {
        case 'm2':
            return 'm<sup>2</sup> ';
        case 'm3':
            return 'm<sup>3</sup>';
        case 'ft2':
            return 'ft<sup>2</sup>';
        case 'ft3':
            return 'ft<sup>3</sup>';
        case 'yd3':
            return 'yd<sup>3</sup>';
        default:
            return unit_name;
    }
};
function renderAttributeTable(
    isBlueprintMap: $TSFixMe,
    data: $TSFixMe,
    featureTags = {},
    featureName = '',
    color: $TSFixMe,
    hideMeasurements: $TSFixMe,
    isImperialSystem: $TSFixMe,
    assemblies = [],
    featureAttributes = [],
    featureAttributesMeasurement = [],
    attributeMapper = {},
    measurementMapper = {}
) {
    let tr = '';
    let id = '';

    const attributes = { ...data };
    const geometry_check = attributes.geometry.getType();
    delete attributes.geometry;
    delete attributes.layerId;
    const geomTags = attributes?.tags_info || {};
    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
    const mergedTags = mergeFeatAndGeomTags(featureTags, [geomTags]);
    // @ts-expect-error TS(2339): Property 'tagTypeMap' does not exist on type '{}'.
    const { tagTypeMap, tagMap, subTagMap } = useTags.getState()?.tagsMap || {};
    delete attributes.tags_info;
    const tagsTooltip = [];
    let isFeatureLevelTagsPresent = false;
    const mergeTagsKeys = Object.keys(mergedTags);
    for (let i = 0; i < mergeTagsKeys.length; i++) {
        const tagTypeId = mergeTagsKeys[i];
        if (tagsTooltip.length >= 5) break;
        // @ts-expect-error TS(2339): Property 'tagId' does not exist on type 'never'.
        const { tagId, subtagId = null, isFeatureTag = false } = mergedTags?.[tagTypeId] || {};
        const { name: tagTypeName } = tagTypeMap?.[tagTypeId] || {};
        if (tagTypeName) {
            const { name: tagName, bg_color, color } = tagMap?.[tagId] || {};
            if (tagName) {
                // it will assign featureTag value only if its true
                isFeatureLevelTagsPresent = isFeatureTag || isFeatureLevelTagsPresent;
                let subtagName = null;
                if (subtagId) {
                    subtagName = subTagMap[subtagId]?.name || null;
                }
                tagsTooltip.push({ tagTypeName, tagName, subtagName, isFeatureTag, bg_color, color });
            }
        }
    }

    const attributes_key = Object.keys(attributes);
    let trAssembly = '';
    const ATTRIBUTE_UNIT_NAME = isImperialSystem ? IMPERIAL_ATTRIBUTE_UNIT_NAMES : METRIC_ATTRIBUTE_UNIT_NAMES;
    const displayCalloutHeading =
        !hideMeasurements &&
        isBlueprintMap &&
        geometry_check !== 'Point' &&
        (attributes.edit_length || attributes.edit_perimeter || attributes.edit_area);

    for (let i = 0; i < attributes_key.length; i++) {
        if (attributes_key[i] !== 'is_manual') {
            let callout_value = 0;
            const callout_visible =
                displayCalloutHeading && ['length', 'perimeter', 'area'].includes(attributes_key[i]);
            let headingName = attributes_key[i];
            if (headingName === 'id') {
                headingName = 'ID';
            } else if (headingName === 'length') {
                callout_value = attributes.edit_length;
                headingName += ` (${getMaterialsUnit(
                    ATTRIBUTE_UNIT_NAME[getLengthMeasurementUnit(isImperialSystem)]
                )})`;
            } else if (headingName === 'perimeter') {
                callout_value = attributes.edit_perimeter;
                headingName += ` (${getMaterialsUnit(
                    ATTRIBUTE_UNIT_NAME[getLengthMeasurementUnit(isImperialSystem)]
                )})`;
            } else if (headingName === 'area') {
                callout_value = attributes.edit_area;
                headingName += ` (${getMaterialsUnit(ATTRIBUTE_UNIT_NAME[getAreaMeasurementUnit(isImperialSystem)])})`;
            } else if (headingName === 'volume') {
                headingName += ` (${getMaterialsUnit(
                    ATTRIBUTE_UNIT_NAME[getVolumeMeasurementUnit(isImperialSystem)]
                )})`;
            } else if (headingName === 'weight') {
                headingName += ` (${getMaterialsUnit(
                    ATTRIBUTE_UNIT_NAME[getWeightMeasurementUnit(isImperialSystem)]
                )})`;
            } else if (headingName === 'weight_ton') {
                headingName += ` (${getMaterialsUnit(
                    ATTRIBUTE_UNIT_NAME[getWeightTonMeasurementUnit(isImperialSystem)]
                )})`;
            } else if (['edit_length', 'edit_area', 'edit_perimeter'].includes(headingName)) {
                continue;
            }
            callout_value = callout_value ? formatCommaNumber(roundNum(callout_value, 2)) : '-';
            const value = formatCommaNumber(roundNum(attributes[attributes_key[i]], 2));
            if (headingName === 'ID') {
                id = value;
                continue;
            }
            tr += `<div class="tooltip-row flex justify-between gap-x-1">
                    <div class='txt-ovf-elp'>${headingName}</div>
                    <div class='txt-ovf-elp text-right'>${value}</div>
                    ${callout_visible ? `<div class='txt-ovf-elp text-right'>${callout_value}</div>` : ''}
                </div>`;
        }
    }

    for (let i = 0; i < assemblies.length; i++) {
        trAssembly += `<div class="tooltip-row flex justify-between gap-x-1">
        <div class='txt-ovf-elp'>${
            // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
            assemblies[i].name.length > 13 ? `${assemblies[i].name.slice(0, 10)}...` : assemblies[i].name
        }:</div>
        <div class='txt-ovf-elp text-right'>${
            hideMeasurements
                ? '-'
                : // @ts-expect-error TS(2339): Property 'value' does not exist on type 'never'.
                  `${formatCommaNumber(convertToFixedDecimal(assemblies[i].value))}&nbsp;${getMaterialsUnit(
                      // @ts-expect-error TS(2339): Property 'unit' does not exist on type 'never'.
                      ASSEMBLY_UNIT_NAMES_V2[assemblies[i].unit]
                  )}`
        }</div>
</div>`;
    }
    let trAttributes = '';
    for (let i = 0; i < featureAttributes.length; i++) {
        // @ts-expect-error
        const featureNamee = attributeMapper[featureAttributes[i]?.name] || featureAttributes[i]?.name;
        trAttributes += `<div class="tooltip-row flex justify-between gap-x-1">
            <div class="flex overflow-hidden">
                <span class='txt-ovf-elp'>${featureNamee}</span>${
                    // @ts-expect-error
                    featureAttributes[i].unit === ATTRIBUTE_UNITS_ENUM.FREETEXT
                        ? ''
                        : `(${
                              ATTRIBUTE_UNITS_VALUE[
                                  // @ts-expect-error
                                  featureAttributes[i].unit
                              ]
                          })`
                }:
            </div>
            <div class='txt-ovf-elp text-right'>${
                hideMeasurements
                    ? '-'
                    : `${
                          // @ts-expect-error TS(2339): Property 'unit' does not exist on type 'never'.
                          featureAttributes[i].unit === METRIC_ATTRIBUTE_UNIT_ENUM.RATIO
                              ? // @ts-expect-error TS(2339): Property 'unit' does not exist on type 'never'.
                                featureAttributes[i].value
                              : // @ts-expect-error TS(2339): Property 'unit' does not exist on type 'never'.
                                featureAttributes[i].unit === METRIC_ATTRIBUTE_UNIT_ENUM.FREETEXT
                                ? // @ts-expect-error TS(2339): Property 'unit' does not exist on type 'never'.
                                  featureAttributes[i].value
                                : // @ts-expect-error TS(2339): Property 'unit' does not exist on type 'never'.
                                  formatCommaNumber(convertToFixedDecimal(featureAttributes[i].value))
                      }`
            }</div>
        </div>`;
    }
    let trAttributesMeasurement = '';
    for (let i = 0; i < featureAttributesMeasurement.length; i++) {
        const measurementNamee =
            // @ts-expect-error
            measurementMapper[featureAttributesMeasurement[i]?.name] || featureAttributesMeasurement[i]?.name;
        trAttributesMeasurement += `<div class="tooltip-row flex justify-between gap-x-1 fw-bold-3">
            <div class="flex overflow-hidden">
                <span class='txt-ovf-elp'>${measurementNamee}</span>(${
                    ATTRIBUTE_UNITS_VALUE[
                        // @ts-expect-error
                        featureAttributesMeasurement[i].unit
                    ]
                }):
            </div>
            <div class='txt-ovf-elp text-right'>${
                hideMeasurements
                    ? '-'
                    : // @ts-expect-error
                      `${formatCommaNumber(convertToFixedDecimal(featureAttributesMeasurement[i]?.value))}`
            }</div>
        </div>`;
    }

    let trTagsInfo = '';
    tagsTooltip.forEach(el => {
        const { color, bg_color, tagTypeName, tagName, subtagName, isFeatureTag } = el || {};
        trTagsInfo += `
        <div class="tooltip-row flex justify-between gap-x-1">
            <div class='txt-ovf-elp'>${tagTypeName}:</div>
            <div class='position-relative tag-value' 
                style='background-color: ${bg_color};color: ${color};border-color: ${color}'>
                ${isFeatureTag ? `<div class='round nudge'></div>` : ''} 
                <div class='txt-ovf-elp'>${tagName}${subtagName ? ` > ${subtagName}` : ''}</div>
            </div>
        </div>`;
    });

    return `
        <div class="geometry-tooltip flex flex-column gap-y-1" style='border-left:5px solid ${color}'>
            <div class="fw-bold-1 flex justify-between gap-x-1">
                <div class='txt-ovf-elp flex-3 line-2'>
                    ${featureName}
                </div>
                <div class='text-right flex-1'>ID-${id}</div>
            </div>
            ${
                displayCalloutHeading
                    ? `<hr />
                      <div class="tooltip-row flex justify-between gap-x-1">
                        <div class='txt-ovf-elp'></div>
                        <div class='txt-ovf-elp text-right'>Measured</div>
                        <div class='txt-ovf-elp text-right'>Callout</div>
                     </div>`
                    : ''
            }
            ${!hideMeasurements && tr ? `<hr />${tr}` : ''}
            ${trAssembly ? `<hr />${trAssembly}` : ''}
            ${featureAttributes?.length ? `<hr />${trAttributes}` : ''}
            ${featureAttributesMeasurement?.length ? `${trAttributesMeasurement}` : ''}
            ${
                trTagsInfo
                    ? `<hr />${trTagsInfo}
                    ${
                        isFeatureLevelTagsPresent
                            ? `<div class='flex align-center gap-x'>
                                <div class='round indicator'></div>
                                <div class="font-10" style="color:#BDBDBD">Feature level tags</div>
                            </div>`
                            : ''
                    }         
                    <div class="font-10 text-center" style="color:#BDBDBD">Use the Detailed report to see all info</div>`
                    : ''
            }
           
        </div>`;
}

const renderOwnerNameOverlay = (name: string) => {
    return `<p class="owner-overlay">${htmlEncode(name)}</p>`;
};

export const htmlEncode = (str: string) => {
    return String(str)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
};

export function transformMutiPolyToFeatures(featureGeojson: $TSFixMe) {
    if (!featureGeojson?.features?.length) return featureGeojson;
    const newFeatures: $TSFixMe = [];

    featureGeojson.features.forEach((feature: $TSFixMe) => {
        if (feature?.geometry?.type === 'MultiPolygon' && feature.geometry.coordinates?.length) {
            feature.geometry.coordinates.forEach((coords: $TSFixMe, inx: $TSFixMe) => {
                if (!isValidPolygon(coords, false)) {
                    coords[0].push(coords[0][0]); // make it fix
                    // setExtra('request-id', requestId);
                    captureException(new Error('Invalid polygon from parcel fetched'));
                }
                newFeatures.push(
                    turfFeature(
                        { type: 'Polygon', coordinates: coords },
                        feature.properties?.collectedProperties?.[inx]
                    )
                );
            });
        } else {
            newFeatures.push(feature);
        }
    });
    return turfFeatureCollection(newFeatures);
}

export function transformFeaturesToMultiPoly(featureGeojson: $TSFixMe) {
    if (!featureGeojson?.features?.length) return featureGeojson;
    const multiPolygon = {
        coordinates: [],
        properties: []
    };

    featureGeojson.features.forEach((feature: $TSFixMe) => {
        if (feature?.geometry?.type === 'Polygon') {
            // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            multiPolygon.coordinates.push(feature.geometry.coordinates);
            // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
            multiPolygon.properties.push(feature.properties);
        }
    });

    return turfFeatureCollection([
        turfFeature(
            { type: 'MultiPolygon', coordinates: multiPolygon.coordinates },
            { collectedProperties: multiPolygon.properties }
        )
    ]);
}
