import 'ol/ol.css';
import Map from 'ol/Map';
import Tile from 'ol/layer/Tile';
import TileImage from 'ol/source/TileImage';
import View from 'ol/View';
import Point from 'ol/geom/Point';
import { fromLonLat, Projection, transformExtent } from 'ol/proj';
import { getCenter } from 'ol/extent';
import { defaults as defaultControls } from 'ol/control';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Circle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import { Icon } from 'ol/style';
import MultiPoint from 'ol/geom/MultiPoint';
import BaseLayer from 'ol/layer/Base';
import Group from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { asArray } from 'ol/color';
import { DragPan, DragRotateAndZoom, KeyboardPan, KeyboardZoom, MouseWheelZoom } from 'ol/interaction';

import {
    ALL_GEOMETRY_TYPES,
    BASE_LAYER_ID,
    CANVAS_PADDING,
    COUNTRY_CENTER,
    COUNTRY_EXTENT,
    EDITABLE_LAYERS,
    FILL_PATTERNS_ENUM,
    GEOMETRY_TYPE_ENUM,
    GEOMETRY_TYPE_STRING,
    GOOGLE_IMAGERY_SATELLITE,
    MAP_LAYERS,
    POINT_SHAPES,
    POINT_SHAPES_ENUM,
    TYPICAL_ICON
} from '../../Constants/Constant';
import { useRequest } from '../../Stores/Request';
import { createPattern } from '../../Utils/olutils';

const GOOGLE_IMAGERY_BASIC = 'http://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}?v=1';

class MapBase {
    baseLayer: any;

    isBlueprintMap: boolean;

    map: any;

    mobileView: boolean;

    enableRightClickDrag: boolean;

    constructor() {
        this.map = null;
        this.baseLayer = null;
        this.isBlueprintMap = false;
        this.mobileView = false;
        this.enableRightClickDrag = false;
    }

    createDefaultMap(view: any, enableRightClickDrag: boolean) {
        this.enableRightClickDrag = enableRightClickDrag;
        this.map = new Map({
            interactions: [
                new DragPan({
                    condition: e => {
                        // @ts-expect-error
                        const toolId = useRequest.getState()?.toolbar?.active?.toolId;
                        const mouseClick = e.originalEvent.button;
                        if (toolId && enableRightClickDrag) {
                            return mouseClick === 1 || mouseClick === 2;
                        }
                        return true;
                    }
                }),
                new KeyboardPan({ duration: 10 }),
                new KeyboardZoom({ duration: 10 }),
                new MouseWheelZoom({ duration: 10, maxDelta: 32, timeout: 10 }),
                new DragRotateAndZoom({
                    condition: e => {
                        const shiftKeyPressed = e.originalEvent.shiftKey;
                        if (!shiftKeyPressed) return false;

                        // @ts-expect-error
                        const toolId = useRequest.getState()?.toolbar?.active?.toolId;
                        const mouseClick = e.originalEvent.button;
                        if (toolId && enableRightClickDrag) {
                            return mouseClick === 1 || mouseClick === 2;
                        }
                        return true;
                    }
                })
            ],
            controls: defaultControls(),
            target: 'map',
            layers: [],
            view: view || new View(),
            ...(!enableRightClickDrag && { moveTolerance: 25 })
        });
    }

    // init called for aerial takeoff
    init(countryCode: string, enableRightClickDrag: boolean) {
        const view = new View({
            center: fromLonLat(COUNTRY_CENTER[countryCode]),
            zoom: 5,
            minZoom: 2,
            maxZoom: 24,
            extent: transformExtent(COUNTRY_EXTENT[countryCode], 'EPSG:4326', 'EPSG:3857')
        });

        this.isBlueprintMap = false;
        this.createDefaultMap(view, enableRightClickDrag);
        this.addBaseLayer();

        return this.map;
    }

    // init called for blueprint takeoff
    initBlueprint(enableRightClickDrag: boolean) {
        this.isBlueprintMap = true;
        this.createDefaultMap(null, enableRightClickDrag);
        return this.map;
    }

    getLayers() {
        return this.map.getLayers();
    }

    getLayerById(id: string) {
        if (!id) return null;
        return this.map?.getLayerById(id);
    }

    getEditableLayers = ({ excludes = [], byId = false } = {}) => {
        const layers = this.map
            .getLayers()
            .getArray()
            .filter(
                // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                (lyr: $TSFixMe) => EDITABLE_LAYERS.includes(lyr.get('name')) && !excludes.includes(lyr.get('name'))
            );
        return byId ? layers.map((lyr: $TSFixMe) => lyr.get('id')) : layers;
    };

    getLayerName = (layer: $TSFixMe) => {
        if (!(layer instanceof BaseLayer)) {
            return this.getLayerById(layer)?.get('name');
        }
        return layer?.get('name');
    };

    getParcelLayer() {
        let layer;
        this.map.getLayers().forEach((lyr: $TSFixMe) => {
            if (lyr.get('name') === MAP_LAYERS.PARCEL) {
                layer = lyr;
            }
        });
        return layer;
    }

    getProjection() {
        return this.map.getView().getProjection();
    }

    getParcelFeaturesAtCoordinate = (coords: $TSFixMe) => {
        // @ts-expect-error TS(2554): Expected 0 arguments, but got 2.
        const parcelLayer = this.getParcelLayer(false, true);
        // @ts-expect-error TS(2339): Property 'getSource' does not exist on type 'never... Remove this comment to see the full error message
        return parcelLayer?.getSource()?.getFeaturesAtCoordinate(coords);
    };

    addBaseLayer() {
        this.baseLayer = new Tile({
            // @ts-expect-error TS(2345): Argument of type '{ id: string; source: TileImage;... Remove this comment to see the full error message
            id: BASE_LAYER_ID,
            source: new TileImage({ url: GOOGLE_IMAGERY_SATELLITE, crossOrigin: 'anonymous' })
        });
        this.addLayer(this.baseLayer);
    }

    addBlueprintBaseLayer(page: $TSFixMe, onImageLoad: $TSFixMe) {
        const extent = [0, -page.height, page.width, 0];
        const modifiedExtent = [
            0 - CANVAS_PADDING,
            -page.height - CANVAS_PADDING,
            page.width + CANVAS_PADDING,
            0 + CANVAS_PADDING
        ];
        const center = getCenter(modifiedExtent);

        const projection = new Projection({ code: 'EPSG:40400', units: 'pixels', extent, global: true });
        const view = new View({
            projection,
            extent: modifiedExtent,
            center,
            zoom: 1.5,
            minZoom: 0,
            showFullExtent: true,
            maxZoom: 24
        });

        const imageLoadFunction = (image: $TSFixMe, src: $TSFixMe) => {
            image.getImage().addEventListener('load', onImageLoad);
            image.getImage().addEventListener('error', () => onImageLoad('error'));
            // eslint-disable-next-line no-param-reassign
            image.getImage().src = src;
        };

        this.map.setView(view);
        this.baseLayer = new ImageLayer({
            // @ts-expect-error TS(2345): Argument of type '{ id: string; properties: { bp_p... Remove this comment to see the full error message
            id: BASE_LAYER_ID,
            properties: {
                bp_page_id: page.id,
                bp_page_scale: page.scale,
                bp_page_dpi: page.dpi,
                bp_page_extent: extent
            },
            source: new Static({
                url: page.image,
                projection,
                imageExtent: extent,
                imageLoadFunction,
                crossOrigin: 'anonymous'
            })
        });

        this.addLayer(this.baseLayer);
        useRequest.getState()?.dispatch({ type: 'SET_SHOW_TIP_MODAL', payload: false });
    }

    changeBaseLayer(l: $TSFixMe) {
        const IMAGERY_URL = l === 'map' ? GOOGLE_IMAGERY_BASIC : GOOGLE_IMAGERY_SATELLITE;
        const _source = new TileImage({ url: IMAGERY_URL, crossOrigin: 'anonymous' });
        this.baseLayer.setSource(_source);
    }

    addLayer(layer: $TSFixMe) {
        this.map.addLayer(layer);
    }

    removeLayer(layer: $TSFixMe) {
        if (layer) {
            this.map.removeLayer(layer);
        }
    }

    removeAllLayers() {
        this.map.setLayerGroup(new Group());
    }

    removeControls() {
        this.map.getControls().forEach((control: $TSFixMe) => {
            this.map.removeControl(control);
        });
    }

    setBPZoomVal({ height, width, value = 0 }: $TSFixMe) {
        const extent = [0 - CANVAS_PADDING, -height - CANVAS_PADDING, width + CANVAS_PADDING, 0 + CANVAS_PADDING];
        const center = getCenter(extent);
        this.map?.getView()?.setCenter(center);
        this.map?.getView()?.setZoom(value);
    }

    zoomToExtent(extent: $TSFixMe) {
        if (extent && extent.length && extent[0] !== Infinity) {
            this.map.getView().fit(extent, {
                duration: 800,
                maxZoom: 20,
                padding: this.mobileView ? [10, 10, 10, 10] : [100, 30, 50, 100]
            });
        }
    }

    getMapExtent() {
        return this.map.getView().calculateExtent(this.map.getSize());
    }

    setMobileView(mobileView: $TSFixMe) {
        this.mobileView = mobileView;
    }

    updateSize() {
        this.map.updateSize();
    }

    coordsExistsInParcel(coords: $TSFixMe, feature: $TSFixMe) {
        if (feature) return feature.getGeometry().intersectsCoordinate(coords);

        return this.getParcelFeaturesAtCoordinate(coords)?.length;
    }

    isGeometryOutOfLotBoundary = ({ geom, boundary }: $TSFixMe) => {
        let coords;

        if (geom.getType() === GEOMETRY_TYPE_STRING.POLYGON) {
            coords = geom.getCoordinates()?.[0];
        } else if (geom.getType() === GEOMETRY_TYPE_STRING.LINESTRING) {
            coords = geom.getCoordinates();
        } else {
            coords = [geom.getCoordinates()];
        }

        for (let i = 0; i < coords.length; i++) {
            const coord = coords[i];
            if (!this.coordsExistsInParcel(coord, boundary)) {
                return true;
            }
        }
        return false;
    };
}

export default MapBase;

// @ts-expect-error TS(2339): Property 'getLayerById' does not exist on type 'Ma... Remove this comment to see the full error message
Map.prototype.getLayerById = function getLayerById(id: $TSFixMe) {
    let layer;
    this?.getLayers()?.forEach(lyr => {
        if (id === lyr.get('id')) {
            layer = lyr;
        }
    });
    return layer;
};

export const MODIFY_STYLE = [
    new Style({
        stroke: new Stroke({
            color: '#4361ee',
            width: 2
        }),
        fill: new Fill({
            color: '#4361ee4f'
        }),
        image: new Circle({
            radius: 5,
            stroke: new Stroke({
                color: '#ffffff'
            }),
            fill: new Fill({
                color: '#4361ee',
                // @ts-expect-error TS(2345): Argument of type '{ color: string; width: number; ... Remove this comment to see the full error message
                width: 1
            })
        })
    }),
    new Style({
        image: new Circle({
            radius: 5,
            fill: new Fill({
                color: '#4361ee'
            }),
            stroke: new Stroke({
                color: '#ffffff',
                width: 1
            })
        }),
        // @ts-expect-error TS(2322): Type '(feature: FeatureLike) => MultiPoint | null'... Remove this comment to see the full error message
        geometry: feature => {
            const geom = feature.getGeometry();
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const coordinates = geom.getCoordinates();
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const type = geom.getType();

            if (typeof coordinates === 'object') {
                if (type === GEOMETRY_TYPE_STRING.LINESTRING) {
                    return new MultiPoint(coordinates);
                }
                if (type === GEOMETRY_TYPE_STRING.POLYGON) {
                    return new MultiPoint(coordinates[0]);
                }
            }
            return null;
        }
    })
];

export const ROTATE_STYLE = (isConstructionUsageMode: $TSFixMe) => [
    new Style({
        stroke: new Stroke({
            color: '#4361ee',
            width: 2
        }),
        fill: new Fill({
            color: '#4361ee4f'
        })
    }),
    new Style({
        image: new Icon({
            src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/rotate.svg',
            scale: 0.8,
            color: isConstructionUsageMode ? 'rgb(0,0,0)' : 'rgb(255,255,255)',
            crossOrigin: 'anonymous'
        }),
        // @ts-expect-error TS(2322): Type '(feature: FeatureLike) => Point | null' is n... Remove this comment to see the full error message
        geometry: feature => {
            const geom = feature.getGeometry();
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const coordinates = geom.getCoordinates();
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const type = geom.getType();

            if (typeof coordinates === 'object') {
                if (type === GEOMETRY_TYPE_STRING.LINESTRING) {
                    return new Point(coordinates[0]);
                } else if (type === GEOMETRY_TYPE_STRING.POLYGON) {
                    return new Point(coordinates[0][0]);
                }
            }
            return null;
        }
    })
];

function createEditingStyle() {
    const styles = {};
    const white = [255, 255, 255, 1];
    const blue = [67, 97, 238, 1];
    const width = 2;
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    styles[ALL_GEOMETRY_TYPES.POLYGON] = [
        new Style({
            stroke: new Stroke({
                color: white,
                width: width + 2
            })
        }),
        new Style({
            fill: new Fill({
                color: [255, 255, 255, 0.3]
            }),
            stroke: new Stroke({
                color: blue,
                width
            })
        })
    ];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    styles[ALL_GEOMETRY_TYPES.MULTI_POLYGON] = styles[ALL_GEOMETRY_TYPES.POLYGON];

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    styles[ALL_GEOMETRY_TYPES.LINE_STRING] = [
        new Style({
            stroke: new Stroke({
                color: white,
                width: width + 2
            })
        }),
        new Style({
            stroke: new Stroke({
                color: blue,
                width
            })
        })
    ];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    styles[ALL_GEOMETRY_TYPES.MULTI_LINE_STRING] = styles[ALL_GEOMETRY_TYPES.LINE_STRING];

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    styles[ALL_GEOMETRY_TYPES.CIRCLE] = styles[ALL_GEOMETRY_TYPES.POLYGON].concat(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        styles[ALL_GEOMETRY_TYPES.LINE_STRING]
    );

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    styles[ALL_GEOMETRY_TYPES.POINT] = [
        new Style({
            image: new Circle({
                radius: width * 2,
                fill: new Fill({
                    color: blue
                }),
                stroke: new Stroke({
                    color: white,
                    width: width / 2
                })
            }),
            zIndex: Infinity
        })
    ];
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    styles[ALL_GEOMETRY_TYPES.MULTI_POINT] = styles[ALL_GEOMETRY_TYPES.POINT];

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    styles[ALL_GEOMETRY_TYPES.GEOMETRY_COLLECTION] = styles[ALL_GEOMETRY_TYPES.POLYGON].concat(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        styles[ALL_GEOMETRY_TYPES.LINE_STRING],
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        styles[ALL_GEOMETRY_TYPES.POINT]
    );

    return styles;
}
export const getDrawStyle = (options = {}) => {
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    const styles = createEditingStyle(options);

    return (feature: $TSFixMe, resolution: $TSFixMe) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        let style = styles[feature.getGeometry().getType()];
        // @ts-expect-error TS(2339): Property 'applyMoreStyle' does not exist on type '... Remove this comment to see the full error message
        if (options.applyMoreStyle) {
            // @ts-expect-error TS(2339): Property 'applyMoreStyle' does not exist on type '... Remove this comment to see the full error message
            style = options.applyMoreStyle(feature, resolution, style);
        }
        return style;
    };
};
export const highlightStyle = ({ layer = null, feature = null, text = null }) => {
    let width = 3;
    if (layer && feature) {
        // @ts-expect-error TS(2339): Property 'get' does not exist on type 'never'.
        if (layer?.get('name') === MAP_LAYERS.ARROW) {
            // @ts-expect-error TS(2339): Property 'setStyle' does not exist on type 'never'... Remove this comment to see the full error message
            return feature.setStyle(layer?.get('layerData')?.style({ feature, color: 'red', width: 8 }));
        }
        // @ts-expect-error TS(2339): Property 'get' does not exist on type 'never'.
        // eslint-disable-next-line no-unsafe-optional-chaining
        width = layer?.get('layerData')?.style?.width + 3 || 3;
    }

    // @ts-expect-error TS(2339): Property 'get' does not exist on type 'never'.
    const style = layer?.get('layerData')?.style;
    const { pattern = null } = style || {};

    // @ts-expect-error TS(2339): Property 'get' does not exist on type 'never'.
    const geomType = layer?.get('layerData')?.feature?.geometry_type;

    let fillStyle = null;
    if (geomType !== GEOMETRY_TYPE_ENUM.POINT && pattern && pattern !== FILL_PATTERNS_ENUM.NO_PATTERN) {
        const fillPattern = createPattern('#FF0000', 0.3, pattern);
        fillStyle = new Fill({ color: fillPattern });
    } else {
        fillStyle = new Fill({ color: 'rgba(255,0,0,0.3)' });
    }

    const strokeStyle = new Stroke({
        color: 'red',
        width
    });
    const imageStyle =
        geomType === GEOMETRY_TYPE_ENUM.POINT && pattern && pattern !== POINT_SHAPES_ENUM.NO_PATTERN
            ? new Icon({
                  src: POINT_SHAPES[pattern]?.image,
                  color: 'red',
                  scale: width / 12,
                  crossOrigin: 'anonymous'
              })
            : geomType === GEOMETRY_TYPE_ENUM.TYPICAL_UNIT
              ? new Icon({
                    src: TYPICAL_ICON.image,
                    color: 'red',
                    scale: width / 12,
                    crossOrigin: 'anonymous'
                })
              : new Circle({
                    fill: fillStyle,
                    stroke: strokeStyle,
                    radius: width
                });

    return new Style({
        fill: fillStyle,
        stroke: strokeStyle,
        image: imageStyle,
        // @ts-expect-error TS(2322): Type 'Text | null' is not assignable to type 'Text... Remove this comment to see the full error message
        text: text
            ? new Text({
                  text,
                  fill: new Fill({
                      color: '#ffffff' // White text color
                  }),
                  backgroundFill: new Fill({
                      color: 'rgba(0, 0, 0, 1)' // Gray background color with opacity
                  }),
                  textAlign: 'center', // Text alignment
                  font: '16px sans-serif',
                  overflow: true
              })
            : null
    });
};

export const HIGHLIGHT_STYLE = highlightStyle({});

export const getHighlightZoneStyle = (text: $TSFixMe) => highlightStyle({ text });

export const HIGHLIGHT_STYLE_NUMERICAL = new Style({
    image: new Icon({
        scale: 1.3,
        color: 'red',
        src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/sharp.svg',
        crossOrigin: 'anonymous'
    })
});

export const ZONAL_STYLE = (name: string, layerColor: string, opacity: number, hide_label: boolean, width: number) => {
    const color = asArray(layerColor).slice();
    color[3] = opacity;

    return new Style({
        stroke: new Stroke({
            color: layerColor,
            width,
            lineDash: [6, 10],
            lineDashOffset: 6
        }),
        fill: new Fill({
            color
        }),
        ...(!hide_label && {
            text: new Text({
                text: name,
                fill: new Fill({
                    color: '#ffffff'
                }),
                backgroundFill: new Fill({
                    color: 'rgba(0, 0, 0, 1)'
                }),
                placement: 'point',
                textBaseline: 'top',
                font: '12px sans-serif',
                overflow: true
            })
        })
    });
};

export const EDIT_STYLE = new Style({
    stroke: new Stroke({
        color: '#4361ee',
        width: 2
    }),
    fill: new Fill({
        color: '#4361ee33'
    })
});

export const getHighlightTextStyle = (oldStyle: $TSFixMe) => {
    const textStyle = oldStyle?.getText();
    return new Style({
        text: new Text({
            font: textStyle?.getFont?.() || 16,
            text: textStyle?.getText?.(),
            fill: new Fill({ color: 'rgba(255, 255, 255, 0.4)' }),
            stroke: new Stroke({ color: 'red', width: 2 }),
            textAlign: textStyle?.getTextAlign?.() || 'left'
        })
    });
};
